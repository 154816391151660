import { type ReactNode, useEffect, useState } from "react";

// @Components
import { Button } from "../button.tsx";

// @Icons
import { X as CloseIcon } from "@phosphor-icons/react";

// @Types
interface ActionBarProps {
  /**
   * The main content to display within the ActionBar.
   * This can include text, elements, or components and can be styled as desired.
   */
  children: ReactNode;
  /**
   * Callback function when the close button is pressed.
   */
  onClose(): void;
  /**
   * Whether the action bar is visible
   */
  isVisible: boolean;
  /**
   * Custom icon for the close button.
   * By default, it renders a small CloseIcon, but you can provide any icon component.
   */
  closeIcon?: ReactNode;
}

const ActionBar = ({
  children = "Hello World",
  closeIcon = <CloseIcon size="16" />,
  isVisible,
  onClose,
}: ActionBarProps) => {
  const [shouldRender, setShouldRender] = useState(isVisible);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    if (isVisible) {
      setShouldRender(true);
      if (isFirstRender) {
        setIsAnimating(true);
        setIsFirstRender(false);
      }
    } else {
      setIsAnimating(true);
      setIsFirstRender(true);
    }
  }, [isVisible, children]);

  const handleAnimationEnd = () => {
    setIsAnimating(false);
    if (!isVisible) {
      setShouldRender(false);
    }
  };

  if (!shouldRender) return null;

  return (
    <div
      className={`fixed left-0 right-0 bottom-6 mx-auto max-w-2xl flex items-center justify-between p-3.5 pl-6 border rounded-[6px] border-gray-200 bg-white shadow-md z-20 transition-opacity duration-300 ${
        isVisible && !isAnimating
          ? "will-change-[opacity,transform] opacity-100"
          : isVisible && isAnimating
            ? "will-change-[opacity,transform] animate-action-bar-enter"
            : "will-change-[opacity,transform] animate-action-bar-exit pointer-events-none"
      }`}
      onAnimationEnd={handleAnimationEnd}
    >
      {children}
      <div className={"ml-2 border-l-[1px] border-l-gray-200"}>
        <Button
          size={"icon"}
          variant={"ghost"}
          className={
            "ml-2 transition-colors duration-200 hover:bg-gray-50 active:bg-gray-100 focus:outline-blue-700 text-gray-600"
          }
          onPress={onClose}
          aria-label={"Close"}
        >
          {closeIcon}
        </Button>
      </div>
    </div>
  );
};
export default ActionBar;
