import { cn } from "@/lib/utils";
import {
  AvailableChartColors,
  type AvailableChartColorsKeys,
  type AvailableChartGradientsKeys,
  chartGradientColors,
  constructCategoryColors,
  deepEqual,
  getActiveClass,
  getGradientId,
  getInactiveClass,
  getYAxisDomain,
} from "@/lib/utils/chartUtils.ts";
import {
  type MouseEvent,
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Bar,
  type BarProps,
  CartesianGrid,
  Label,
  BarChart as RechartsBarChart,
  Legend as RechartsLegend,
  ResponsiveContainer,
  Text,
  Tooltip,
  XAxis,
  type XAxisProps,
  YAxis,
} from "recharts";
import type { Payload as TooltipPayload } from "recharts/types/component/DefaultTooltipContent";
import type { AxisDomain } from "recharts/types/util/types";

import type {
  BarChartProps,
  BarShapeProps,
  PayloadItem,
  ShapeProps,
} from "./BarChart.types";
import { ChartLegend } from "./ChartLegend";
import ChartTooltip from "./ChartTooltip";
type ValueType = string | number | Array<string | number>;
type NameType = string | number;

const BarShape = memo(function Shape<T extends PayloadItem>({
  props,
  activeBar,
  activeLegend,
  layout,
  mode,
  colorKey,
  borderRadius = 0,
}: BarShapeProps<T>): React.ReactElement {
  const { name, payload, value, x = 0, y = 0, width = 0, height = 0 } = props;

  let correctedX = Number.parseInt(x.toString(), 10);
  let correctedY = Number.parseInt(y.toString(), 10);
  let correctedWidth = width;
  let correctedHeight = height;

  if (layout === "horizontal" && height < 0) {
    correctedY += height;
    correctedHeight = Math.abs(height);
  } else if (layout === "vertical" && width < 0) {
    correctedX += width;
    correctedWidth = Math.abs(width);
  }

  const fill =
    mode === "gradient" ? `url(#${getGradientId(colorKey)})` : undefined;

  const isInactive = useMemo(
    () =>
      activeBar || (activeLegend && name && activeLegend !== name)
        ? !deepEqual(activeBar, { ...payload, value })
        : false,
    [activeBar, activeLegend, name, payload, value],
  );

  return (
    <rect
      className={cn(
        "transform-gpu will-change-[fill,opacity] transition-opacity duration-200",
        {
          [getActiveClass(colorKey)]: !isInactive && mode === "solid",
          [getInactiveClass(colorKey)]: isInactive,
        },
      )}
      x={correctedX}
      y={correctedY}
      width={correctedWidth}
      height={correctedHeight}
      fill={fill}
      rx={borderRadius}
      ry={borderRadius}
      stroke={value === 0 ? "none" : "white"}
      strokeWidth={value === 0 ? 0 : 1}
    />
  );
});

BarShape.displayName = "BarShape";

const RotatedXAxisTick = memo(
  ({
    x,
    y,
    payload,
    formatXAxisTick,
    index,
  }: {
    x: number;
    y: number;
    payload: {
      value: string | number;
      coordinate: number;
      index: number;
    };
    formatXAxisTick?: XAxisProps["tickFormatter"];
    index: number;
  }) => (
    <text
      x={x}
      y={y}
      dy={10}
      textAnchor="end"
      transform={`rotate(-45, ${x}, ${y})`}
      className="fill-gray-500 text-[9px] dark:fill-gray-300"
    >
      {formatXAxisTick ? formatXAxisTick(payload.value, index) : payload.value}
    </text>
  ),
);

// Custom Y-axis tick component that measures its width
const MeasurableYAxisTick = memo(
  ({
    x,
    y,
    payload,
    valueFormatter,
    onWidthChange,
  }: {
    x: number;
    y: number;
    payload: {
      value: string | number;
      coordinate: number;
      index: number;
    };
    valueFormatter?: (value: number) => string;
    onWidthChange?: (width: number) => void;
  }) => {
    const textRef = useRef<SVGTextElement>(null);

    useLayoutEffect(() => {
      if (textRef.current && onWidthChange) {
        const width = textRef.current.getBBox().width;
        onWidthChange(width);
      }
    }, [payload.value, onWidthChange]);

    return (
      <Text
        x={x}
        y={y}
        textAnchor="end"
        verticalAnchor="middle"
        className="fill-gray-500 text-[9px] dark:fill-gray-300"
        ref={textRef}
      >
        {valueFormatter && typeof payload.value === "number"
          ? valueFormatter(payload.value)
          : payload.value}
      </Text>
    );
  },
);

MeasurableYAxisTick.displayName = "MeasurableYAxisTick";

RotatedXAxisTick.displayName = "RotatedXAxisTick";

const BarChart = memo(
  forwardRef<HTMLDivElement, BarChartProps<PayloadItem>>(
    (props, forwardedRef) => {
      const {
        data = [],
        categories = [],
        index,
        colors = AvailableChartColors,
        valueFormatter = (value: number) => value.toString(),
        startEndOnly = false,
        showXAxis = true,
        showYAxis = true,
        showGridLines = true,
        yAxisWidth = 56,
        intervalType = "equidistantPreserveStart",
        showTooltip = true,
        showLegend = true,
        autoMinValue = true,
        minValue,
        maxValue,
        allowDecimals = true,
        className,
        onValueChange,
        enableLegendSlider = false,
        barCategoryGap,
        tickGap = 5,
        xAxisLabel,
        yAxisLabel,
        layout = "horizontal",
        type = "default",
        tooltipCallback,
        customTooltip,
        borderRadius,
        formatXAxisTick,
        rotateXAxisTick,
        mode = "solid",
        defaultActiveBar,
        ...other
      } = props;

      const [legendHeight, setLegendHeight] = useState(60);
      const [calculatedYAxisWidth, setCalculatedYAxisWidth] =
        useState(yAxisWidth);
      const [maxYAxisLabelWidth, setMaxYAxisLabelWidth] = useState(0);
      const [activeLegend, setActiveLegend] = useState<string | undefined>(
        undefined,
      );
      const [activeBar, setActiveBar] = useState<PayloadItem | undefined>(
        defaultActiveBar,
      );
      const chartContainerRef = useRef<HTMLDivElement>(null);
      const isFirstRender = useRef(true);

      const CustomTooltip = customTooltip;
      const paddingValue =
        (!showXAxis && !showYAxis) || (startEndOnly && !showYAxis) ? 0 : 20;
      const categoryColors = useMemo(
        () => constructCategoryColors(categories, colors),
        [categories, colors],
      );
      const yAxisDomain = useMemo(
        () => getYAxisDomain(autoMinValue, minValue, maxValue),
        [autoMinValue, minValue, maxValue],
      );
      const hasOnValueChange = !!onValueChange;
      const stacked = type === "stacked" || type === "percent";

      // Update Y-axis width based on measured label widths
      useEffect(() => {
        if (maxYAxisLabelWidth > 0) {
          // Add padding to the measured width
          const newWidth = Math.max(maxYAxisLabelWidth + 16, 40);
          setCalculatedYAxisWidth(newWidth);
        }
      }, [maxYAxisLabelWidth]);

      // Handle Y-axis label width measurement
      const handleYAxisLabelWidthChange = useCallback((width: number) => {
        setMaxYAxisLabelWidth((prev) => Math.max(prev, width));
      }, []);

      useEffect(() => {
        setActiveBar(defaultActiveBar);
      }, [defaultActiveBar]);

      // Reset max width when data changes
      useEffect(() => {
        setMaxYAxisLabelWidth(0);
      }, [data]);

      // Add animation for initial render
      useEffect(() => {
        if (isFirstRender.current && chartContainerRef.current) {
          chartContainerRef.current.style.opacity = "0";

          // Delay to ensure DOM is ready
          const timer = setTimeout(() => {
            if (chartContainerRef.current) {
              chartContainerRef.current.style.opacity = "1";
              isFirstRender.current = false;
            }
          }, 50);

          return () => clearTimeout(timer);
        }
      }, []);

      const valueToPercent = useCallback((value: number) => {
        return `${(value * 100).toFixed(0)}%`;
      }, []);

      const onBarClick = useCallback(
        (
          data: {
            payload: PayloadItem;
            value: number;
            tooltipPayload?: { dataKey: string }[];
          },
          _: unknown,
          event: MouseEvent,
        ) => {
          event.stopPropagation();
          if (!onValueChange) return;

          if (deepEqual(activeBar, { ...data.payload, value: data.value })) {
            setActiveLegend(undefined);
            setActiveBar(undefined);
            onValueChange?.(null);
          } else {
            setActiveLegend(data.tooltipPayload?.[0]?.dataKey);
            setActiveBar({
              ...data.payload,
              value: data.value,
            });
            onValueChange?.({
              eventType: "bar",
              categoryClicked: data.tooltipPayload?.[0]?.dataKey ?? "",
              ...data.payload,
              value: data.value,
              // biome-ignore lint/suspicious/noExplicitAny: <type it more later>
            } as any);
          }
        },
        [activeBar, onValueChange],
      );

      const handleChartClick = useCallback(() => {
        if (hasOnValueChange && (activeLegend || activeBar)) {
          setActiveBar(undefined);
          setActiveLegend(undefined);
          onValueChange?.(null);
        }
      }, [hasOnValueChange, activeLegend, activeBar, onValueChange]);

      return (
        <div
          ref={forwardedRef}
          className={cn(
            "h-80 w-full transform-gpu will-change-[transform]",
            "transition-all duration-300 ease-in-out",
            className,
          )}
          tremor-id="tremor-raw"
          {...other}
        >
          <div
            ref={chartContainerRef}
            className="w-full h-full transition-opacity duration-500 ease-in-out"
          >
            <ResponsiveContainer>
              <RechartsBarChart
                data={data}
                onClick={
                  hasOnValueChange && (activeLegend || activeBar)
                    ? handleChartClick
                    : undefined
                }
                margin={{
                  bottom: xAxisLabel ? 30 : rotateXAxisTick ? 40 : 20,
                  left: yAxisLabel ? 20 : 0,
                  right: 10,
                  top: 10,
                }}
                stackOffset={type === "percent" ? "expand" : undefined}
                layout={layout}
                barCategoryGap={barCategoryGap}
                className="transition-all duration-300 ease-in-out"
              >
                {mode === "gradient" && (
                  <defs>
                    {categories.map((category) => (
                      <linearGradient
                        key={category}
                        id={getGradientId(
                          categoryColors.get(
                            category,
                          ) as AvailableChartColorsKeys,
                        )}
                        gradientTransform={"rotate(90)"}
                      >
                        {chartGradientColors[
                          categoryColors.get(
                            category,
                          ) as AvailableChartGradientsKeys
                        ]?.stops.map((stop, index) => (
                          <stop
                            // biome-ignore lint/suspicious/noArrayIndexKey: <legacy>
                            key={index}
                            offset={stop.offset}
                            stopColor={stop.color}
                            stopOpacity="1"
                          />
                        ))}
                      </linearGradient>
                    ))}
                  </defs>
                )}

                {showGridLines ? (
                  <CartesianGrid
                    className={cn(
                      "stroke-gray-200 stroke-1 dark:stroke-gray-800",
                    )}
                    horizontal={layout !== "vertical"}
                    vertical={layout === "vertical"}
                  />
                ) : null}
                <XAxis
                  hide={!showXAxis}
                  tick={
                    rotateXAxisTick
                      ? (props) => (
                          <RotatedXAxisTick
                            {...props}
                            formatXAxisTick={formatXAxisTick}
                          />
                        )
                      : {
                          transform:
                            layout !== "vertical"
                              ? "translate(0, 6)"
                              : undefined,
                        }
                  }
                  fill=""
                  stroke=""
                  className={cn(
                    "text-[9px]",
                    "fill-gray-500 dark:fill-gray-500",
                    { "mt-4": layout !== "vertical" },
                  )}
                  tickLine={false}
                  axisLine={false}
                  minTickGap={tickGap}
                  tickFormatter={formatXAxisTick}
                  {...(layout !== "vertical"
                    ? {
                        padding: {
                          left: paddingValue,
                          right: paddingValue,
                        },
                        dataKey: index,
                        interval: startEndOnly
                          ? "preserveStartEnd"
                          : intervalType,
                        ticks: startEndOnly
                          ? //@ts-ignore
                            [data[0][index], data[data.length - 1][index]]
                          : undefined,
                      }
                    : {
                        type: "number",
                        domain: yAxisDomain as AxisDomain,
                        tickFormatter:
                          type === "percent" ? valueToPercent : valueFormatter,
                        allowDecimals: allowDecimals,
                      })}
                >
                  {xAxisLabel && (
                    <Label
                      position="insideBottom"
                      offset={-20}
                      className="fill-gray-800 text-sm font-medium dark:fill-gray-200"
                    >
                      {xAxisLabel}
                    </Label>
                  )}
                </XAxis>
                <YAxis
                  width={calculatedYAxisWidth}
                  hide={!showYAxis}
                  axisLine={false}
                  tickLine={false}
                  fill=""
                  stroke=""
                  className={cn(
                    "text-[9px]",
                    "fill-gray-500 dark:fill-gray-500",
                  )}
                  tick={(props) => (
                    <MeasurableYAxisTick
                      {...props}
                      valueFormatter={
                        type === "percent" ? valueToPercent : valueFormatter
                      }
                      onWidthChange={handleYAxisLabelWidthChange}
                    />
                  )}
                  {...(layout !== "vertical"
                    ? {
                        type: "number",
                        domain: yAxisDomain as AxisDomain,
                        tickFormatter:
                          type === "percent" ? valueToPercent : valueFormatter,
                        allowDecimals: allowDecimals,
                      }
                    : {
                        dataKey: index,
                        ticks: startEndOnly
                          ? //@ts-ignore
                            [data[0][index], data[data.length - 1][index]]
                          : undefined,
                        type: "category",
                        interval: "equidistantPreserveStart",
                      })}
                >
                  {yAxisLabel && (
                    <Label
                      position="insideLeft"
                      style={{ textAnchor: "middle" }}
                      angle={-90}
                      offset={-15}
                      className="fill-gray-800 text-sm font-medium dark:fill-gray-200"
                    >
                      {yAxisLabel}
                    </Label>
                  )}
                </YAxis>
                <Tooltip
                  shared={false}
                  wrapperStyle={{ outline: "none" }}
                  animationDuration={180}
                  wrapperClassName="transition-transform duration-180 ease-out"
                  cursor={{ fill: "transparent", opacity: "0.15" }}
                  offset={20}
                  content={({ active, payload, label }) => {
                    const cleanPayload = useMemo(() => {
                      if (!payload) return [];
                      return payload.map((item) => {
                        const tooltipItem = item as TooltipPayload<
                          ValueType,
                          NameType
                        > & {
                          dataKey: string;
                          value: number;
                          payload: PayloadItem;
                        };
                        return {
                          category: tooltipItem.dataKey,
                          value: tooltipItem.value,
                          index: tooltipItem.payload[index] as string,
                          color: categoryColors.get(
                            tooltipItem.dataKey,
                          ) as AvailableChartColorsKeys,
                          type: item.type,
                          payload: item.payload,
                          id: data.findIndex((d) => d === item.payload),
                        };
                      }) as PayloadItem[];
                    }, [payload, data, index, categoryColors]);

                    return showTooltip && active ? (
                      CustomTooltip ? (
                        <CustomTooltip
                          active={active}
                          payload={cleanPayload}
                          label={label}
                        />
                      ) : (
                        <ChartTooltip
                          active={active}
                          payload={cleanPayload}
                          label={label}
                          valueFormatter={valueFormatter}
                          mode={mode}
                          formatXAxisTick={formatXAxisTick}
                        />
                      )
                    ) : null;
                  }}
                />
                {showLegend ? (
                  <RechartsLegend
                    verticalAlign="bottom"
                    height={legendHeight}
                    wrapperStyle={{ transform: "translateZ(0)" }}
                    content={({ payload }) => {
                      return (
                        <ChartLegend
                          payload={payload || []}
                          categoryColors={categoryColors}
                          setLegendHeight={setLegendHeight}
                          yAxisWidth={calculatedYAxisWidth}
                          activeLegend={activeLegend}
                          enableLegendSlider={enableLegendSlider}
                          rotateXAxisTick={rotateXAxisTick}
                        />
                      );
                    }}
                  />
                ) : null}
                {categories.map((category) => {
                  const colorKey = categoryColors.get(
                    category,
                  ) as AvailableChartColorsKeys;
                  const activeClass = getActiveClass(colorKey);
                  const inactiveClass = getInactiveClass(colorKey);

                  return (
                    <Bar
                      className={cn(
                        "transform-gpu will-change-[transform,opacity] transition-[opacity,transform] duration-300 ease-out",
                        activeBar ? inactiveClass : activeClass,
                        onValueChange ? "cursor-pointer" : "",
                      )}
                      key={category}
                      name={category}
                      type="linear"
                      dataKey={category}
                      stackId={stacked ? "stack" : undefined}
                      isAnimationActive={true}
                      animationDuration={200}
                      style={{
                        transitionDelay: activeBar
                          ? `${categories.indexOf(category) * 50}ms`
                          : "0ms",
                        opacity:
                          activeBar && activeBar.category !== category
                            ? 0.3
                            : 1,
                      }}
                      shape={(props: BarProps) => (
                        <BarShape
                          props={props as ShapeProps<PayloadItem>}
                          activeBar={activeBar}
                          activeLegend={activeLegend}
                          layout={layout}
                          mode={mode}
                          colorKey={colorKey}
                          borderRadius={borderRadius}
                        />
                      )}
                      onClick={onBarClick}
                      maxBarSize={30}
                    />
                  );
                })}
              </RechartsBarChart>
            </ResponsiveContainer>
          </div>
        </div>
      );
    },
  ),
);

BarChart.displayName = "BarChart";

export default BarChart;
