export type ColorUtility =
  | "bg"
  | "stroke"
  | "fill"
  | "text"
  | "from"
  | "to"
  | "inactive";

export type ChartColorObject = {
  active: string | AvailableChartColorsKeys;
  inactive: string | AvailableChartColorsKeys;
};

export const isChartColorObject = (
  color: unknown,
): color is ChartColorObject => {
  return (
    typeof color === "object" &&
    color !== null &&
    "active" in color &&
    "inactive" in color
  );
};

export const chartColors = {
  blue: {
    bg: "bg-blue-500",
    stroke: "stroke-blue-500",
    fill: "fill-blue-500",
    text: "text-blue-500",
    from: "from-blue-400",
    to: "to-blue-600",
    inactive: "fill-blue-200",
  },
  orange: {
    bg: "bg-orange-500",
    stroke: "stroke-orange-500",
    fill: "fill-orange-500",
    text: "text-orange-500",
    from: "from-orange-400",
    to: "to-orange-600",
    inactive: "fill-orange-200",
  },
  violet: {
    bg: "bg-violet-700",
    stroke: "stroke-violet-700",
    fill: "fill-violet-700",
    text: "text-violet-700",
    from: "from-violet-600",
    to: "to-violet-700",
    inactive: "fill-violet-200",
  },
  green: {
    bg: "bg-green-700",
    stroke: "stroke-green-700",
    fill: "fill-green-700",
    text: "text-green-700",
    from: "from-green-400",
    to: "to-green-600",
    inactive: "fill-green-200",
  },
  gray: {
    bg: "bg-gray-300",
    stroke: "stroke-gray-300",
    fill: "fill-gray-300",
    text: "text-gray-300",
    from: "from-gray-400",
    to: "to-gray-600",
    inactive: "fill-gray-200",
  },
  teal: {
    bg: "bg-teal-500",
    stroke: "stroke-teal-500",
    fill: "fill-teal-500",
    text: "text-teal-500",
    from: "from-teal-400",
    to: "to-teal-600",
    inactive: "fill-teal-200",
  },
  rose: {
    bg: "bg-rose-500",
    stroke: "stroke-rose-500",
    fill: "fill-rose-500",
    text: "text-rose-500",
    from: "from-rose-400",
    to: "to-rose-600",
    inactive: "fill-rose-200",
  },
  yellow: {
    bg: "bg-yellow-500",
    stroke: "stroke-yellow-500",
    fill: "fill-yellow-500",
    text: "text-yellow-500",
    from: "from-yellow-400",
    to: "to-yellow-600",
    inactive: "fill-yellow-200",
  },
  fuchsia: {
    bg: "bg-fuchsia-500",
    stroke: "stroke-fuchsia-500",
    fill: "fill-fuchsia-500",
    text: "text-fuchsia-500",
    from: "from-fuchsia-400",
    to: "to-fuchsia-600",
    inactive: "fill-fuchsia-200",
  },
} as const satisfies {
  [color: string]: {
    [key in ColorUtility]: string;
  };
};

export const chartGradientColors = {
  blue: {
    id: "blueGradient",
    stops: [
      { offset: "0%", color: "oklch(97% 0.0152 257.6)" },
      { offset: "100%", color: "oklch(40.75% 0.184 257.6)" },
    ],
  },
  orange: {
    id: "orangeGradient",
    stops: [
      { offset: "0%", color: "oklch(63.05% 0.2 41.49)" },
      { offset: "100%", color: "oklch(27% 0.0886 41.49)" },
    ],
  },
  violet: {
    id: "violetGradient",
    stops: [
      { offset: "0%", color: "oklch(61% 0.245 293.03)" },
      { offset: "100%", color: "oklch(54.25% 0.245 293.02)" },
    ],
  },
  green: {
    id: "greenGradient",
    stops: [
      { offset: "0%", color: "oklch(97% 0.049 149.18)" },
      { offset: "100%", color: "oklch(41.75% 0.1251 149.18)" },
    ],
  },
  gray: {
    id: "grayGradient",
    stops: [
      { offset: "0%", color: "oklch(98% 0.0067 286.07)" },
      { offset: "100%", color: "oklch(21% 0.0067 286.07)" },
    ],
  },
  teal: {
    id: "tealGradient",
    stops: [
      { offset: "0%", color: "oklch(97% 0.0277 210.83)" },
      { offset: "100%", color: "oklch(27% 0.062 210.83)" },
    ],
  },
  rose: {
    id: "roseGradient",
    stops: [
      { offset: "0%", color: "oklch(97% 0.0186 27.16)" },
      { offset: "100%", color: "oklch(27% 0.1237 27.16)" },
    ],
  },
  yellow: {
    id: "yellowGradient",
    stops: [
      { offset: "0%", color: "oklch(97% 0.0442 89.137)" },
      { offset: "100%", color: "oklch(27% 0.0635 89.137)" },
    ],
  },
  fuchsia: {
    id: "fuchsiaGradient",
    stops: [
      { offset: "0%", color: "oklch(97% 0.0234 317.04)" },
      { offset: "100%", color: "oklch(27% 0.1409 317.04)" },
    ],
  },
};

export type AvailableChartGradientsKeys = keyof typeof chartGradientColors;
export type BuiltInChartColor = keyof typeof chartColors;
export type AvailableChartColorsKeys = BuiltInChartColor | `chart-${string}`;
export type ChartColorInput = AvailableChartColorsKeys | ChartColorObject;

export const AvailableChartColors: AvailableChartColorsKeys[] = Object.keys(
  chartColors,
) as Array<AvailableChartColorsKeys>;

export const getColorClassName = (
  colorInput: ChartColorInput,
  type: ColorUtility,
  mode: "solid" | "gradient" = "solid",
): string => {
  // Handle custom color object
  if (isChartColorObject(colorInput)) {
    let color: string;
    if (type === "inactive") {
      // Handle inactive color
      const inactiveColor = colorInput.inactive;
      // Check if inactive color is a built-in color
      const builtInInactive = chartColors[inactiveColor as BuiltInChartColor];
      color = builtInInactive ? builtInInactive.inactive : inactiveColor;
    } else {
      // Handle active color
      const activeColor = colorInput.active;
      // Check if active color is a built-in color
      const builtInActive = chartColors[activeColor as BuiltInChartColor];
      if (builtInActive) {
        if (mode === "gradient") {
          return `${builtInActive.from} ${builtInActive.to} bg-gradient-to-br`;
        }
        return builtInActive[type];
      }
      color = activeColor;
    }
    return getUtilityClassName(type, color);
  }

  // Rest of the existing code remains the same
  const builtIn = chartColors[colorInput as BuiltInChartColor];
  if (builtIn) {
    if (mode === "gradient") {
      return `${builtIn.from} ${builtIn.to} bg-gradient-to-br`;
    }
    return builtIn[type];
  }

  if (mode === "gradient") {
    return `from-${colorInput} to-${colorInput} bg-gradient-to-br`;
  }

  return getUtilityClassName(type, colorInput);
};

const getUtilityClassName = (type: ColorUtility, color: string): string => {
  switch (type) {
    case "bg":
      return `bg-${color}`;
    case "stroke":
      return `stroke-${color}`;
    case "fill":
      return `fill-${color}`;
    case "text":
      return `text-${color}`;
    case "from":
      return `from-${color}`;
    case "to":
      return `to-${color}`;
    case "inactive":
      return `fill-${color}`;
    default:
      return "bg-gray-300";
  }
};

export const getGradientId = (colorKey: ChartColorInput): string => {
  if (typeof colorKey === "string" && colorKey in chartGradientColors) {
    return chartGradientColors[colorKey as AvailableChartGradientsKeys].id;
  }
  return "grayGradient";
};

export const getActiveClass = (colorInput: ChartColorInput): string => {
  if (isChartColorObject(colorInput)) {
    const activeColor = colorInput.active;
    const builtInActive = chartColors[activeColor as BuiltInChartColor];
    if (builtInActive) {
      return builtInActive.fill;
    }
    return `fill-${activeColor}`;
  }
  return getColorClassName(colorInput, "fill");
};

export const getInactiveClass = (colorInput: ChartColorInput): string => {
  if (isChartColorObject(colorInput)) {
    const inactiveColor = colorInput.inactive;
    const builtInInactive = chartColors[inactiveColor as BuiltInChartColor];
    if (builtInInactive) {
      return builtInInactive.inactive;
    }
    return `fill-${inactiveColor}`;
  }

  const builtIn = chartColors[colorInput as BuiltInChartColor];
  if (builtIn) {
    return builtIn.inactive;
  }

  return `fill-${colorInput}`;
};

export const constructCategoryColors = (
  categories: string[],
  colors: ChartColorInput[],
): Map<string, ChartColorInput> => {
  const categoryColors = new Map<string, ChartColorInput>();
  categories.forEach((category, index) => {
    categoryColors.set(category, colors[index % colors.length] ?? "gray");
  });
  return categoryColors;
};

export const getYAxisDomain = (
  autoMinValue: boolean,
  minValue: number | undefined,
  maxValue: number | undefined,
) => {
  const minDomain = autoMinValue ? "auto" : (minValue ?? 0);
  const maxDomain = maxValue ?? "auto";
  return [minDomain, maxDomain];
};

// Tremor Raw hasOnlyOneValueForKey [v0.1.0]

export function hasOnlyOneValueForKey(
  // biome-ignore lint/suspicious/noExplicitAny: <function needs to work with any object type>
  array: any[],
  keyToCheck: string,
): boolean {
  // biome-ignore lint/suspicious/noExplicitAny: <values can be of any type>
  const val: any[] = [];

  for (const obj of array) {
    if (Object.prototype.hasOwnProperty.call(obj, keyToCheck)) {
      val.push(obj[keyToCheck]);
      if (val.length > 1) {
        return false;
      }
    }
  }

  return true;
}

/** Deep equality check for objects
 * @param obj1 - first object to compare
 * @param obj2 - second object to compare
 * @returns {boolean} `true` if the objects are deeply equal, `false` otherwise.
 *
 * @example
 * deepEqual({ a: 1, b: 2 }, { a: 1, b: 2 }) // true
 * deepEqual({ a: 1, b: 2 }, { a: 1, b: 3 }) // false
 * */
export const deepEqual = <T>(obj1: T, obj2: T): boolean => {
  if (obj1 === obj2) return true;

  if (
    typeof obj1 !== "object" ||
    typeof obj2 !== "object" ||
    obj1 === null ||
    obj2 === null
  ) {
    return false;
  }

  const keys1 = Object.keys(obj1) as Array<keyof T>;
  const keys2 = Object.keys(obj2) as Array<keyof T>;

  if (keys1.length !== keys2.length) return false;

  for (const key of keys1) {
    if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) return false;
  }

  return true;
};
