import type React from "react";
import { forwardRef } from "react";
import {
  Tooltip as AriaTooltip,
  type TooltipProps as AriaTooltipProps,
  TooltipTrigger as AriaTooltipTrigger,
  composeRenderProps,
} from "react-aria-components";
import { getTooltipStyles } from "./tooltip-styles";

// Use the AriaTooltipTrigger directly
const TooltipTrigger = (
  props: React.ComponentProps<typeof AriaTooltipTrigger>,
) => <AriaTooltipTrigger delay={200} {...props} />;
TooltipTrigger.displayName = "TooltipTrigger";

interface CustomTooltipProps extends AriaTooltipProps {
  align?: "left" | "right" | "center";
  variant?: "default" | "accessibility";
}

const Tooltip = forwardRef<HTMLDivElement, CustomTooltipProps>(
  (
    { className, offset = 4, align = "center", variant = "default", ...props },
    ref,
  ) => (
    <AriaTooltip
      ref={ref}
      offset={offset}
      className={composeRenderProps(
        className,
        (className) =>
          getTooltipStyles(variant, className) +
          (align === "left"
            ? " text-left"
            : align === "right"
              ? " text-right"
              : " text-center"),
      )}
      {...props}
    />
  ),
);

Tooltip.displayName = "Tooltip";
export { Tooltip, TooltipTrigger };
