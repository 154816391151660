import {
  ProgressBar as AriaProgressBar,
  type ProgressBarProps as AriaProgressBarProps,
  composeRenderProps,
} from "react-aria-components";

import { cn } from "@/lib/utils";

interface ProgressProps extends AriaProgressBarProps {
  barClassName?: string;
  fillClassName?: string;
}

const Progress = ({
  className,
  barClassName,
  fillClassName,
  children,
  ...props
}: ProgressProps) => (
  <AriaProgressBar
    className={composeRenderProps(className, (className) =>
      cn("w-full", className),
    )}
    {...props}
  >
    {composeRenderProps(children, (children, renderProps) => (
      <>
        {children}
        <div
          className={cn(
            "relative h-4 w-full overflow-hidden rounded-full bg-slate-100 dark:bg-slate-800",
            barClassName,
          )}
        >
          <div
            className={cn(
              "size-full flex-1 bg-slate-900 transition-all dark:bg-slate-50",
              fillClassName,
            )}
            style={{
              transform: `translateX(-${100 - (renderProps.percentage || 0)}%)`,
            }}
          />
        </div>
      </>
    ))}
  </AriaProgressBar>
);

interface JollyProgressBarProps extends ProgressProps {
  label?: string;
  showValue?: boolean;
}

export { Progress };
export type { ProgressProps, JollyProgressBarProps };
