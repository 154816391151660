import { Check, Minus } from "@phosphor-icons/react";
import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import { type VariantProps, cva } from "class-variance-authority";
import {
  type ComponentPropsWithoutRef,
  type ElementRef,
  forwardRef,
} from "react";

// @Utils
import { cn } from "@/lib/utils";

// @Type
export interface CheckboxProps
  extends ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>,
    VariantProps<typeof checkboxVariants> {
  isSelectAll?: boolean;
  isPartialSelect?: boolean;
  iconClassNames?: string;
}

const checkboxVariants = cva(
  "peer bg-white h-4 w-4 shrink-0 ring-offset-white focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-blue-750 focus-visible:ring-offset-2 disabled:opacity-50",
  {
    variants: {
      variant: {
        primary:
          "rounded border border-slate-200 hover:bg-white hover:border-violet-800 data-[state=checked]:bg-violet-800 data-[state=checked]:border-none disabled:border-grey-200",
        secondary:
          "rounded-sm border border-slate-200 dark:border-slate-800 hover:bg-white hover:border-gray-600 disabled:border-grey-200 text-slate-800",
      },
    },
    defaultVariants: {
      variant: "primary",
    },
  },
);

const Checkbox = forwardRef<
  ElementRef<typeof CheckboxPrimitive.Root>,
  CheckboxProps
>(
  (
    {
      className,
      iconClassNames,
      isSelectAll = false,
      isPartialSelect = false,
      variant = "primary",
      ...props
    },
    ref,
  ) => {
    const iconMap = {
      selectAllChecked: (
        <Check
          className={cn(
            "h-[11px] w-[11px] text-white",
            variant === "secondary" && props.checked && "text-slate-800",
          )}
        />
      ),
      selectAllPartial: (
        <Minus
          className={cn("h-[11px] w-[11px] text-slate-800", iconClassNames)}
        />
      ),

      defaultChecked: (
        <Check
          className={cn(
            "h-[11px] w-[11px]",
            iconClassNames,
            variant === "secondary" && props.checked
              ? "text-slate-800"
              : "text-white",
          )}
        />
      ),
    };

    const renderIcon = () => {
      if (isSelectAll)
        return isPartialSelect
          ? iconMap.selectAllPartial
          : iconMap.selectAllChecked;
      return iconMap.defaultChecked;
    };

    return (
      <div className="flex items-center space-x-2">
        <CheckboxPrimitive.Root
          ref={ref}
          className={cn(checkboxVariants({ variant }), className)}
          {...props}
        >
          <CheckboxPrimitive.Indicator
            forceMount
            className={cn("flex items-center justify-center text-current")}
          >
            {renderIcon()}
          </CheckboxPrimitive.Indicator>
        </CheckboxPrimitive.Root>
        {props.children && (
          <label
            htmlFor={props.id}
            className="text-sm pl-0.5 text-gray-700 leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
          >
            {props.children}
          </label>
        )}
      </div>
    );
  },
);

Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export { Checkbox };
export default Checkbox;
