export const OwnerInitials = ({
  owner,
  name,
  email,
}: { owner?: string; name: string; email: string }) => {
  const nameFromEmail = email.split("@")[0];
  const displayName = owner || name || nameFromEmail;
  const initials = (displayName as string)
    .split(" ")
    .slice(0, 2)
    .map((namePart) => namePart[0])
    .join("")
    .toUpperCase();
  return <span className="uppercase">{initials}</span>;
};
