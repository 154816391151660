import { cn } from "@/lib/utils";

// Common base styles for tooltips
const tooltipBaseStyles =
  "z-50 overflow-hidden rounded-md py-1.5 px-2 text-xs tracking-wide font-normal shadow-sm animate-in fade-in-0";

// Animation styles
export const tooltipAnimationStyles = [
  /* Entering */
  "data-[entering]:zoom-in-95",
  /* Exiting */
  "data-[exiting]:animate-out data-[exiting]:fade-out-0 data-[exiting]:zoom-out-95",
  /* Placement */
  "data-[placement=bottom]:slide-in-from-top-2 data-[placement=left]:slide-in-from-right-2 data-[placement=right]:slide-in-from-left-2 data-[placement=top]:slide-in-from-bottom-2",
].join(" ");

// Variant styles
export const tooltipVariantStyles = {
  default: "bg-gray-900 text-white shadow-sm",
  accessibility:
    "bg-white text-gray-900 shadow-sm text-xs border border-gray-200",
  white:
    "bg-white text-gray-900 shadow-sm border border-gray-200 max-w-xs whitespace-normal break-words text-left",
};

// Helper function to get tooltip styles
export function getTooltipStyles(
  variant: keyof typeof tooltipVariantStyles = "default",
  className?: string,
) {
  return cn(
    tooltipBaseStyles,
    tooltipAnimationStyles,
    tooltipVariantStyles[variant],
    className,
  );
}
