import { Check, CircleNotch, MagnifyingGlass } from "@phosphor-icons/react";
// import type { DialogProps } from "@radix-ui/react-dialog";
import { Command as CommandPrimitive } from "cmdk";
import * as React from "react";

import { cn } from "@/lib/utils";
// import { Dialog, DialogContent } from "../dialog";

const Command = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive>
>(({ className, ...props }, ref) => (
  <CommandPrimitive
    ref={ref}
    className={cn(
      "flex w-full flex-col overflow-hidden shadow-none",
      "bg-white text-slate-950",
      "dark:bg-slate-950 dark:text-slate-50",
      className,
    )}
    {...props}
  />
));
Command.displayName = CommandPrimitive.displayName;

// interface CommandDialogProps extends DialogProps {}

// const CommandDialog = ({ children, ...props }: CommandDialogProps) => {
//   return (
//     <Dialog {...props}>
//       <DialogContent className="overflow-hidden p-0 shadow-lg">
//         <Command
//           className={cn(
//             // Group heading styles
//             "[&_[cmdk-group-heading]]:px-2",
//             "[&_[cmdk-group-heading]]:font-medium",
//             "[&_[cmdk-group-heading]]:text-slate-500",
//             // Group styles
//             "[&_[cmdk-group]:not([hidden])_~[cmdk-group]]:pt-0",
//             "[&_[cmdk-group]]:px-2",
//             // Input wrapper styles
//             "[&_[cmdk-input-wrapper]_svg]:h-5",
//             "[&_[cmdk-input-wrapper]_svg]:w-5",
//             "[&_[cmdk-input]]:h-12",
//             // Item styles
//             "[&_[cmdk-item]]:px-2",
//             "[&_[cmdk-item]]:py-3",
//             "[&_[cmdk-item]_svg]:h-5",
//             "[&_[cmdk-item]_svg]:w-5",
//             // Dark mode
//             "dark:[&_[cmdk-group-heading]]:text-slate-400",
//           )}
//         >
//           {children}
//         </Command>
//       </DialogContent>
//     </Dialog>
//   );
// };

const CommandInput = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Input>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Input> & {
    loading?: boolean;
  }
>(({ className, loading, onKeyDown, ...props }, ref) => (
  // Extract onKeyDown to handle it separately
  <div
    className={cn(
      "flex items-center px-3 border rounded-md group",
      "border-gray-200 h-9",
      "has-[*:focus]:border-blue-600",
      // "has-[*:focus]:ring-blue-600",
      "has-[*:focus]:ring-0 ring-offset-0",
    )}
    cmdk-input-wrapper=""
  >
    {loading ? (
      <CircleNotch className="mr-2 h-4 w-4 shrink-0 animate-spin opacity-50 text-gray-900" />
    ) : (
      <MagnifyingGlass className="mr-2 h-4 w-4 shrink-0 opacity-50 text-gray-900" />
    )}
    <CommandPrimitive.Input
      ref={ref}
      className={cn(
        "flex w-full bg-transparent border-transparent py-0",
        "text-xs outline-none",
        "placeholder:text-gray-500",
        "disabled:opacity-50",
        "dark:placeholder:text-slate-400",
        "focus:outline-none",
        className,
      )}
      // Only prevent space key from being captured
      onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
        // Always prevent space key from being captured by the parent Command component
        if (e.key === " " || e.key === "Spacebar") {
          e.stopPropagation();
        }

        // Call the original onKeyDown handler if it exists
        onKeyDown?.(e);
      }}
      {...props}
    />
  </div>
));
CommandInput.displayName = CommandPrimitive.Input.displayName;

const CommandList = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.List>
>(({ className, ...props }, ref) => (
  <CommandPrimitive.List
    ref={ref}
    className={cn(
      "max-h-[300px] h-auto overflow-y-auto overflow-x-hidden py-1 max-w-[342px]",
      className,
    )}
    {...props}
  />
));

CommandList.displayName = CommandPrimitive.List.displayName;

const CommandEmpty = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Empty>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Empty>
>((props, ref) => (
  <CommandPrimitive.Empty
    ref={ref}
    className="py-6 text-center text-sm"
    {...props}
  />
));

CommandEmpty.displayName = CommandPrimitive.Empty.displayName;

const CommandGroup = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Group>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Group>
>(({ className, ...props }, ref) => (
  <CommandPrimitive.Group
    ref={ref}
    className={cn(
      "overflow-hidden p-1 text-slate-950 [&_[cmdk-group-heading]]:px-2 [&_[cmdk-group-heading]]:py-1.5 [&_[cmdk-group-heading]]:text-xs [&_[cmdk-group-heading]]:font-medium [&_[cmdk-group-heading]]:text-slate-500 dark:text-slate-50 dark:[&_[cmdk-group-heading]]:text-slate-400",
      className,
    )}
    {...props}
  />
));

CommandGroup.displayName = CommandPrimitive.Group.displayName;

const CommandSeparator = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Separator>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Separator>
>(({ className, ...props }, ref) => (
  <CommandPrimitive.Separator
    ref={ref}
    className={cn("-mx-1 h-px bg-slate-200 dark:bg-slate-800", className)}
    {...props}
  />
));
CommandSeparator.displayName = CommandPrimitive.Separator.displayName;

const CommandItem = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Item> & {
    showIcon?: boolean;
  }
>(({ className, showIcon, ...props }, ref) => {
  // Don't create a wrapped handler, just use the original props
  return (
    <CommandPrimitive.Item
      ref={ref}
      className={cn(
        "relative flex cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none aria-selected:bg-slate-600/10 aria-selected:text-slate-800 dark:aria-selected:bg-slate-800 dark:aria-selected:text-slate-50",
        "cursor-pointer",
        showIcon && "pr-6",
        className,
      )}
      {...props}
    >
      {props.children}
      {showIcon && (
        <span className="absolute right-1 p-1">
          <Check className="h-4 w-4" />
        </span>
      )}
    </CommandPrimitive.Item>
  );
});

CommandItem.displayName = CommandPrimitive.Item.displayName;

const CommandShortcut = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLSpanElement>) => {
  return (
    <span
      className={cn(
        "ml-auto text-xs tracking-widest text-slate-500 dark:text-slate-400",
        className,
      )}
      {...props}
    />
  );
};
CommandShortcut.displayName = "CommandShortcut";

export {
  Command,
  CommandInput,
  CommandList,
  CommandEmpty,
  CommandGroup,
  CommandItem,
};
