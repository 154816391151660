import type { VariantProps } from "class-variance-authority";
import {
  DateField as AriaDateField,
  type DateFieldProps as AriaDateFieldProps,
  DateInput as AriaDateInput,
  type DateInputProps as AriaDateInputProps,
  DateSegment as AriaDateSegment,
  type DateSegmentProps as AriaDateSegmentProps,
  type DateValue as AriaDateValue,
  // TimeField as AriaTimeField,
  type TimeFieldProps as AriaTimeFieldProps,
  type TimeValue as AriaTimeValue,
  type ValidationResult as AriaValidationResult,
  // Text,
  composeRenderProps,
} from "react-aria-components";

import { cn } from "@/lib/utils";

import { fieldGroupVariants } from "./field";

const DateField = AriaDateField;

// const TimeField = AriaTimeField;

function DateSegment({ className, ...props }: AriaDateSegmentProps) {
  return (
    <AriaDateSegment
      className={composeRenderProps(className, (className) =>
        cn(
          "type-literal:px-0 inline rounded p-0.5 caret-transparent outline outline-0",
          /* Placeholder */
          "data-[placeholder]:text-slate-500 dark:data-[placeholder]:text-slate-400",
          /* Disabled */
          "data-[disabled]:opacity-50",
          /* Focused */
          "data-[focused]:bg-slate-100 data-[focused]:text-slate-900 dark:data-[focused]:bg-slate-800 dark:data-[focused]:text-slate-50",
          /* Invalid */
          "data-[invalid]:data-[focused]:bg-red-500 data-[invalid]:data-[focused]:data-[placeholder]:text-slate-50 data-[invalid]:data-[focused]:text-slate-50 data-[invalid]:data-[placeholder]:text-red-500 data-[invalid]:text-red-500 dark:data-[invalid]:data-[focused]:bg-red-900 dark:data-[invalid]:data-[focused]:data-[placeholder]:text-slate-50 dark:data-[invalid]:data-[focused]:text-slate-50 dark:data-[invalid]:data-[placeholder]:text-red-900 dark:data-[invalid]:text-red-900",
          className,
        ),
      )}
      {...props}
    />
  );
}

interface DateInputProps
  extends AriaDateInputProps,
    VariantProps<typeof fieldGroupVariants> {}

function DateInput({
  className,
  variant,
  ...props
}: Omit<DateInputProps, "children">) {
  return (
    <AriaDateInput
      className={composeRenderProps(className, (className) =>
        cn(fieldGroupVariants({ variant }), "text-sm", className),
      )}
      {...props}
    >
      {(segment) => <DateSegment segment={segment} />}
    </AriaDateInput>
  );
}

interface JollyDateFieldProps<T extends AriaDateValue>
  extends AriaDateFieldProps<T> {
  label?: string;
  description?: string;
  errorMessage?: string | ((validation: AriaValidationResult) => string);
}

interface JollyTimeFieldProps<T extends AriaTimeValue>
  extends AriaTimeFieldProps<T> {
  label?: string;
  description?: string;
  errorMessage?: string | ((validation: AriaValidationResult) => string);
}

export { DateField, DateInput };
export type { DateInputProps, JollyDateFieldProps, JollyTimeFieldProps };
