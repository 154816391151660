import { cn } from "@/lib/utils";
import { CircleNotch, X } from "@phosphor-icons/react";
import { type VariantProps, cva } from "class-variance-authority";
import type React from "react";
import {
  Dialog as AriaDialog,
  type DialogProps as AriaDialogProps,
  Heading as AriaHeading,
  type HeadingProps as AriaHeadingProps,
  Modal as AriaModal,
  ModalOverlay as AriaModalOverlay,
  type ModalOverlayProps as AriaModalOverlayProps,
  Separator,
  composeRenderProps,
} from "react-aria-components";
import { Button } from "./button";

const sheetVariants = cva(
  [
    "fixed z-50 gap-4 bg-white shadow-lg transition ease-in-out dark:bg-slate-950",
    "will-change-[opacity,transform] transform-gpu backface-visibility-hidden",
  ],
  {
    variants: {
      side: {
        top: "inset-x-0 top-0 border-b data-[entering]:slide-in-from-top data-[exiting]:slide-out-to-top",
        bottom:
          "inset-x-0 bottom-0 border-t data-[entering]:slide-in-from-bottom data-[exiting]:slide-out-to-bottom",
        left: "inset-y-0 left-0 h-full w-3/4 border-r data-[entering]:slide-in-from-left data-[exiting]:slide-out-to-left sm:max-w-sm",
        right:
          "inset-y-0 right-0 h-full w-3/4 border-l data-[entering]:slide-in-from-right data-[exiting]:slide-out-to-right sm:max-w-sm",
      },
      size: {
        sm: "w-fit min-w-[295px] w-[295px]",
        default: "w-fit min-w-[295px] w-[540px]",
        lg: "w-fit min-w-[295px] w-[1098px]",
      },
    },
    defaultVariants: {
      size: "default",
    },
  },
);

const DialogOverlay = ({
  className,
  isDismissable = true,
  ...props
}: AriaModalOverlayProps) => (
  <AriaModalOverlay
    isDismissable={isDismissable}
    className={composeRenderProps(className, (className) =>
      cn(
        "fixed inset-0 z-50 bg-gray-600/40",
        "data-[exiting]:duration-100 data-[exiting]:animate-out data-[exiting]:fade-out-0",
        "data-[entering]:duration-150 data-[entering]:animate-in data-[entering]:fade-in-0",
        className,
      ),
    )}
    {...props}
  />
);

interface DialogContentProps
  extends Omit<React.ComponentProps<typeof AriaModal>, "children">,
    VariantProps<typeof sheetVariants> {
  children?: AriaDialogProps["children"];
  role?: AriaDialogProps["role"];
  closeButton?: boolean;
  onClose?: () => void;
}

const DialogContent = ({
  className,
  children,
  side,
  role,
  onClose,
  closeButton = true,
  size = "default",
  ...props
}: DialogContentProps) => (
  <AriaModal
    className={composeRenderProps(className, (className) =>
      cn(
        // Core styles
        "fixed left-[50vw] top-1/2 z-50 -translate-x-1/2 -translate-y-1/2",
        "flex flex-col",
        "border border-slate-200 bg-white",
        "max-h-[calc(100vh-40px)] min-h-[100px] max-w-[calc(100vw-40px)]",
        "shadow-lg",
        // Animation entrance
        "data-[entering]:animate-in data-[entering]:fade-in-0",
        "data-[entering]:zoom-in-95",
        "data-[entering]:slide-in-from-left-1/2",
        "data-[entering]:slide-in-from-top-[48%]",
        // Animation exit
        "data-[exiting]:animate-out data-[exiting]:duration-100",
        "data-[exiting]:fade-out-0 data-[exiting]:zoom-out-95",
        "data-[exiting]:slide-out-to-left-1/2",
        "data-[exiting]:slide-out-to-top-[48%]",
        "sm:rounded-lg",
        "dark:border-slate-800 dark:bg-slate-950",
        // Animation
        "transition-[transform,opacity] duration-200",
        side ? sheetVariants({ side }) : sheetVariants({ size }),
        className,
      ),
    )}
    {...props}
  >
    <AriaDialog
      role={role}
      className={cn("flex flex-col flex-1 min-h-0 outline-none")}
    >
      {composeRenderProps(children, (children, renderProps) => (
        <>
          {children}
          {closeButton && (
            <Button
              variant="ghost"
              onPress={() => {
                renderProps.close();
                onClose?.();
              }}
              className={cn(
                "absolute right-[14px] top-2.5 p-2.5",
                "rounded-sm opacity-70 ring-offset-white transition-opacity",
                "text-gray-800",
                "dark:ring-offset-slate-950",
                "dark:data-[entering]:bg-slate-800",
                "dark:data-[entering]:text-slate-400",
                "dark:data-[focused]:ring-slate-300",
              )}
            >
              <X className="size-4" />
              <span className="sr-only">Close</span>
            </Button>
          )}
        </>
      ))}
    </AriaDialog>
  </AriaModal>
);

const DialogBody = ({
  className,
  children,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      "flex-1 min-h-0",
      "overflow-y-auto overflow-x-hidden",
      "p-6 pt-4",
      className,
    )}
    {...props}
  >
    {children}
  </div>
);

const DialogHeader = ({
  className,
  title,
  children,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <>
    <div
      className={cn(
        "flex flex-col justify-center space-y-1.5",
        "text-sm font-medium leading-tight",
        "text-gray-800",
        "text-center sm:text-left",
        "p-6 pt-5 pr-14",
        className,
      )}
      {...props}
    >
      {title && <DialogTitle>{title}</DialogTitle>}
      {children}
    </div>
    <Separator className="border-gray-200/50" />
  </>
);

interface DialogFooterProps extends React.HTMLAttributes<HTMLDivElement> {
  primaryLabel?: string;
  primaryAction?: () => void;
  secondaryLabel?: string;
  secondaryAction?: () => void;
  primaryDisabled?: boolean;
  secondaryDisabled?: boolean;
  isPending?: boolean;
}

const DialogFooter = ({
  className,
  children,
  primaryAction,
  primaryDisabled,
  primaryLabel,
  secondaryAction,
  secondaryDisabled,
  secondaryLabel,
  isPending,
  ...props
}: DialogFooterProps) => {
  return (
    <>
      <Separator className="border-gray-200/50" />
      <div
        className={cn(
          "flex flex-col-reverse sm:flex-row sm:justify-end gap-2 px-6 py-4",
          className,
        )}
        {...props}
      >
        {children}
        {secondaryLabel && (
          <Button
            variant="outline"
            onPressChange={secondaryAction}
            isDisabled={secondaryDisabled}
            type="button"
            size="sm"
          >
            {secondaryLabel}
          </Button>
        )}
        {primaryLabel && (
          <Button
            onPress={primaryAction}
            isDisabled={primaryDisabled}
            type="button"
            size="sm"
            isPending={isPending}
            className={cn(primaryDisabled && "disabled:bg-gray-600")}
          >
            {isPending && (
              <CircleNotch className="animate-spin mr-2" size={16} />
            )}
            {primaryLabel}
          </Button>
        )}
      </div>
    </>
  );
};

const DialogTitle = ({ className, ...props }: AriaHeadingProps) => (
  <AriaHeading
    slot="title"
    className={cn("pt-1 leading-5", className)}
    {...props}
  />
);

export {
  DialogOverlay,
  DialogContent,
  DialogHeader,
  DialogFooter,
  DialogBody,
  // DialogTitle, for now no overrides
};
export type { DialogContentProps };
