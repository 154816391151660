import {
  Button,
  type ButtonProps,
  Input,
  type InputProps,
  type SearchFieldProps,
  SearchField as _SearchField,
} from "react-aria-components";

import { cn } from "@/lib/utils";

function SearchFieldInput({ className, ...props }: InputProps) {
  return (
    <Input
      className={cn(
        "min-w-0  flex-1 bg-background px-2 py-1.5 outline outline-0",
        "[&::-webkit-search-cancel-button]:hidden",
        className,
      )}
      {...props}
    />
  );
}

function SearchField({ className, ...props }: SearchFieldProps) {
  return (
    <_SearchField
      className={cn(
        "bg-white border-0 border-gray-200 flex focus-visible:ring-2 focus-visible:ring-blue-750 focus-visible:ring-offset-2 focus-within:border-slate-700/40 focus-within:outline-none focus-within:ring-0 focus-within:ring-slate-400 group group-data-[disabled]:opacity-50 items-center placeholder:text-muted-foreground px-0 ring-offset-background rounded-none text-xxs",
        className,
      )}
      {...props}
    />
  );
}

function SearchFieldClear({ className, ...props }: ButtonProps) {
  return (
    <Button
      className={cn(
        "mr-1 rounded-sm opacity-70 ring-offset-background transition-opacity",
        "hover:opacity-100 group-data-[disabled]:pointer-events-none",
        "group-data-[empty]:invisible",
        className,
      )}
      {...props}
    />
  );
}

export { SearchField, SearchFieldInput, SearchFieldClear };
