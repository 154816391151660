import { cn } from "@/lib/utils";
import React from "react";

interface CardProps extends React.HTMLAttributes<HTMLDivElement> {
  onClick?: () => void;
  onKeyDown?: (event: React.KeyboardEvent) => void;
  className?: string;
  style?: React.CSSProperties;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  children: React.ReactNode;
  tabIndex?: number;
  role?: string;
  "aria-selected"?: boolean;
}

const Card = React.forwardRef<HTMLDivElement, CardProps>(
  (
    {
      onClick,
      onKeyDown,
      className,
      style,
      onMouseEnter,
      onMouseLeave,
      children,
      tabIndex = -1,
      role,
      "aria-selected": ariaSelected,
      ...props
    },
    ref,
  ) => (
    <div
      className={cn(
        "focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-blue-750",
        "px-6 bg-white rounded-lg border border-gray-200/60",
        "shadow-[0_2px_8px_0_rgba(0,0,0,0.04)]",
        className,
      )}
      style={style}
      tabIndex={tabIndex}
      ref={ref}
      onClick={onClick}
      onKeyDown={onKeyDown}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      role={role}
      aria-selected={ariaSelected}
      {...props}
    >
      {children}
    </div>
  ),
);

Card.displayName = "Card";

export default Card;
