import { cn } from "@/lib/utils";
import { ChartBar } from "@phosphor-icons/react";

interface PlaceholderProps {
  className?: string;
  yAxisWidth?: number;
}

// region NoDataView
const NoDataView = ({ className, yAxisWidth = 56 }: PlaceholderProps) => {
  return (
    <div
      className={cn(
        "h-80 w-full relative transition-opacity duration-300 ease-in-out",
        className,
      )}
    >
      {/* SVG for simulating grid, axes, and labels */}
      <svg className="w-full h-full" role="presentation">
        {/* Y-axis area */}
        <rect x="0" y="0" width={yAxisWidth} height="100%" fill="transparent" />

        {[...Array(6)].map((_, index) => {
          const yPosition = `${10 + index * 16}%`; // Y position for the grid line
          return (
            <g key={`grid-line-${yPosition}`}>
              {/* Static Text Label */}
              {/* <text
                x={yAxisWidth - 8} // Position to the left of the grid line
                y={yPosition}
                dy="0.3em" // Center-align vertically with the line
                fontSize="10"
                fill="gray"
                opacity="0.6"
                textAnchor="end"
              >
                0
              </text> */}
              {/* Grid Line */}
              <line
                x1={yAxisWidth}
                x2="98%"
                y1={yPosition}
                y2={yPosition}
                stroke="gray"
                strokeWidth="0.7"
                strokeOpacity="0.1"
              />
            </g>
          );
        })}
      </svg>

      <div className="absolute inset-0 flex flex-col items-center justify-center text-gray-400 animate-fade-in">
        <ChartBar size={20} />
        <p className="text-[10px] mt-2">No data available</p>
      </div>
    </div>
  );
};

//# region ChartSkeleton
const ChartSkeleton = ({ className, yAxisWidth = 56 }: PlaceholderProps) => {
  return (
    <div
      className={cn(
        "h-80 w-full relative transition-opacity duration-300 ease-in-out",
        className,
      )}
    >
      {/* SVG for simulating grid, axes, and labels */}
      <svg className="w-full h-full" role="presentation">
        {/* Y-axis area */}
        <rect x="0" y="0" width={yAxisWidth} height="100%" fill="transparent" />

        {[...Array(6)].map((_, index) => {
          const yPosition = `${10 + index * 16}%`; // Y position for the grid line
          return (
            <g key={`grid-line-${yPosition}`}>
              {/* Static Text Label */}
              {/* <text
                x={yAxisWidth - 8} // Position to the left of the grid line
                y={yPosition}
                dy="0.3em" // Center-align vertically with the line
                fontSize="10"
                fill="gray"
                opacity="0.6"
                textAnchor="end"
              >
                  <Skeleton className="w-4 h-4" />
              </text> */}
              {/* Grid Line */}
              <line
                x1={yAxisWidth}
                x2="98%"
                y1={yPosition}
                y2={yPosition}
                stroke="gray"
                strokeWidth="0.7"
                strokeOpacity="0.1"
              />
            </g>
          );
        })}
      </svg>

      {/* X-axis labels */}
      <div className="absolute bottom-0 left-0 right-0 h-6 flex justify-between px-10">
        {[...Array(5)].map((_, index) => (
          <div
            key={`x-label-position-${index + 1}-of-5`}
            className="h-2 w-10 bg-gray-200 rounded-sm animate-pulse"
            style={{
              animationDelay: `${index * 100}ms`,
              marginLeft: index === 0 ? yAxisWidth : 0,
            }}
          />
        ))}
      </div>

      <div
        className="flex absolute inset-0 justify-center items-center"
        style={{ paddingLeft: yAxisWidth }}
      >
        <div className="flex items-end space-x-4 h-40">
          {[...Array(8)].map((_, index) => (
            <div
              key={`skeleton-bar-${index + 1}-of-8`}
              className="bg-gray-200 animate-pulse rounded-sm"
              style={{
                height: `${Math.random() * 70 + 10}%`,
                width: "20px",
                animationDelay: `${index * 75}ms`,
                opacity: 0.7,
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

// Add keyframes for fade-in animation to your global CSS or tailwind config
// @keyframes fadeIn {
//   from { opacity: 0; }
//   to { opacity: 1; }
// }
// .animate-fade-in {
//   animation: fadeIn 0.5s ease-in-out;
// }

export { NoDataView, ChartSkeleton };
