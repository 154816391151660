import { Circle } from "@phosphor-icons/react";
import {
  Radio as AriaRadio,
  RadioGroup as AriaRadioGroup,
  type RadioGroupProps as AriaRadioGroupProps,
  type RadioProps as AriaRadioProps,
  type ValidationResult as AriaValidationResult,
  composeRenderProps,
} from "react-aria-components";

import { cn } from "@/lib/utils";

import { labelVariants } from "./field";

const RadioGroup = ({
  className,
  orientation = "vertical",
  ...props
}: AriaRadioGroupProps) => {
  return (
    <AriaRadioGroup
      className={composeRenderProps(className, (className) =>
        cn(
          {
            "grid gap-1.5": orientation === "vertical",
            "flex items-center gap-2": orientation === "horizontal",
          },
          className,
        ),
      )}
      {...props}
    />
  );
};

const Radio = ({ className, children, ...props }: AriaRadioProps) => {
  return (
    <AriaRadio
      className={composeRenderProps(className, (className) =>
        cn(
          "group flex items-center gap-x-2.5 cursor-pointer",
          /* Disabled */
          "data-[disabled]:opacity-70",
          labelVariants,
          className,
        ),
      )}
      {...props}
    >
      {composeRenderProps(children, (children, renderProps) => (
        <>
          <span
            className={cn(
              "jolly-Radio flex aspect-square size-4 items-center justify-center rounded-full border border-gray-500 text-gray-700 ring-offset-white dark:text-slate-300 dark:ring-offset-gray-950 cursor-pointer",
              /* Focus */
              "group-data-[focused]:outline-none",
              /* Focus Visible */
              "group-data-[focus-visible]:ring-2 group-data-[focus-visible]:ring-blue-750 group-data-[focus-visible]:ring-offset-2 dark:group-data-[focus-visible]:ring-blue-300",
              /* Disabled */
              "group-data-[disabled]:opacity-50",
              /* Invalid */
              "group-data-[invalid]:border-red-500 dark:group-data-[invalid]:border-red-900",
              "group-data-[hovered]:border-violet-800 group-data-[hovered]:shadow",
              renderProps.isSelected && "bg-violet-800 border-violet-800",
            )}
          >
            {renderProps.isSelected && (
              <Circle
                weight="fill"
                className="size-2 fill-white text-slate-500"
              />
            )}
          </span>
          <span className="text-gray-700">{children}</span>
        </>
      ))}
    </AriaRadio>
  );
};

interface JollyRadioGroupProps extends AriaRadioGroupProps {
  label?: string;
  description?: string;
  errorMessage?: string | ((validation: AriaValidationResult) => string);
}

export { Radio, RadioGroup };
export type { JollyRadioGroupProps };
