import { Check } from "@phosphor-icons/react";
import {
  Collection as AriaCollection,
  // Header as AriaHeader,
  // ListBox as AriaListBox,
  ListBoxItem as AriaListBoxItem,
  type ListBoxItemProps as AriaListBoxItemProps,
  // type ListBoxProps as AriaListBoxProps,
  Section as AriaSection,
  composeRenderProps,
} from "react-aria-components";

import { cn } from "@/lib/utils";

const ListBoxSection = AriaSection;

const ListBoxCollection = AriaCollection;

const ListBoxItem = <T extends object>({
  className,
  children,
  ...props
}: AriaListBoxItemProps<T>) => {
  return (
    <AriaListBoxItem
      textValue={
        props.textValue || (typeof children === "string" ? children : undefined)
      }
      className={composeRenderProps(className, (className) =>
        cn(
          // Base styles
          [
            "relative flex w-full",
            "cursor-default select-none",
            "items-center rounded-sm",
            "px-2 py-1 text-xs text-gray-800",
            "outline-none",
          ],
          // State variations
          {
            // Disabled state
            "data-[disabled]:pointer-events-none data-[disabled]:opacity-50": true,

            // Focus & Hover states - Light mode
            "data-[focused]:bg-slate-100 data-[focused]:text-slate-900": true,
            "data-[hovered]:bg-slate-100 data-[hovered]:text-slate-900": true,

            // Focus & Hover states - Dark mode
            "dark:data-[focused]:bg-slate-800 dark:data-[focused]:text-slate-50": true,
            "dark:data-[hovered]:bg-slate-800 dark:data-[hovered]:text-slate-50": true,
          },

          className,
        ),
      )}
      {...props}
    >
      {composeRenderProps(children, (children, renderProps) => (
        <div
          className={cn(
            renderProps.isSelected ? "font-medium" : "font-normal",
            "flex gap-2 items-center",
          )}
        >
          {children}
          {renderProps.isSelected && (
            <span className="absolute right-1 flex size-4 items-center justify-center">
              <Check className="size-4" />
            </span>
          )}
        </div>
      ))}
    </AriaListBoxItem>
  );
};

export { ListBoxItem, ListBoxSection, ListBoxCollection };
