import { cn } from "@/lib/utils";
import type { MouseEvent as ReactMouseEvent } from "react";

interface ResizeHandleProps {
  onMouseDown: (e: ReactMouseEvent) => void;
  className?: string;
}

export const ResizeHandle: React.FC<ResizeHandleProps> = ({
  onMouseDown,
  className,
}) => {
  return (
    // biome-ignore lint/a11y/useKeyWithClickEvents: <not needed>
    // biome-ignore lint/a11y/useFocusableInteractive: <not needed>
    <div
      className={cn(
        // Position it outside with a wider hit area
        "absolute -right-2 top-0 h-full w-4 cursor-ew-resize select-none",
        // Visible line in the middle
        "after:absolute after:left-1/2 after:top-0 after:h-full after:w-0.5 after:bg-transparent",
        // More noticeable hover state
        "hover:after:bg-gray-300",
        // Active state clearly visible
        "active:after:bg-violet-700",
        className,
      )}
      onMouseDown={onMouseDown}
      onClick={(e) => e.stopPropagation()}
      role="separator"
      aria-orientation="vertical"
      aria-label="Resize"
    />
  );
};
