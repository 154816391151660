import { cn } from "@/lib/utils";
import { Check } from "@phosphor-icons/react";
import { Fragment } from "react";
import {
  Menu,
  MenuItem,
  MenuPopover,
  MenuSeparator,
  MenuSubTrigger,
} from "./menu";

export type MenuItemConfig = {
  icon: React.ReactNode;
  label: string;
  onClick?: () => void;
  className?: string;
  separator?: boolean;
  subMenu?: {
    items: {
      id: string;
      name: React.ReactNode;
      icon?: React.ReactNode;
      checked?: boolean;
      onItemClick?: (id: string) => void;
    }[];
    onItemClick?: (id: string) => void;
  };
};

export const CustomMenuItem = ({ config }: { config: MenuItemConfig }) => {
  if (config.subMenu) {
    return (
      <MenuSubTrigger>
        <MenuItem className={config.className || "text-xs"}>
          {config.icon}
          <span>{config.label}</span>
        </MenuItem>
        <MenuPopover>
          <Menu>
            {config.subMenu.items.map((item, index) => (
              <Fragment key={item.id}>
                {config.separator &&
                  config.subMenu &&
                  index === config.subMenu.items.length - 1 && (
                    <MenuSeparator className="mx-1" />
                  )}
                <MenuItem
                  className={cn("text-xs", item.checked && "pr-2")}
                  onAction={() =>
                    item.onItemClick?.(item.id) ||
                    config.subMenu?.onItemClick?.(item.id)
                  }
                >
                  {item.icon}
                  <span>{item.name}</span>
                  {item.checked && (
                    <span className="ml-auto">
                      <Check />
                    </span>
                  )}
                </MenuItem>
              </Fragment>
            ))}
          </Menu>
        </MenuPopover>
      </MenuSubTrigger>
    );
  }

  return (
    <MenuItem
      className={config.className || "text-xs"}
      onAction={config.onClick}
    >
      {config.icon}
      {config.label}
    </MenuItem>
  );
};
