import { cn } from "@/lib/utils";
import { cva, type VariantProps } from "class-variance-authority";
import {
  composeRenderProps,
  FieldError as AriaFieldError,
  type FieldErrorProps as AriaFieldErrorProps,
  Group as AriaGroup,
  type GroupProps as AriaGroupProps,
  Label as AriaLabel,
  type LabelProps as AriaLabelProps,
} from "react-aria-components";

const labelVariants = cva(
  [
    "text-sm font-medium leading-none flex leading-4",
    /* Disabled  state*/
    "data-[disabled]:opacity-70",
    /* Margin Bottom */
    "mb-2 leading-5",
  ],
  {
    variants: {
      variant: {
        default: "text-gray-600 text-sm font-medium ",
        secondary: "text-gray-700 text-xs tracking-[0.12px]",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  },
);

const Label = ({
  className,
  variant = "default",
  ...props
}: AriaLabelProps & { variant?: "default" | "secondary" }) => (
  <AriaLabel className={cn(labelVariants({ variant }), className)} {...props} />
);

function FieldError({ className, ...props }: AriaFieldErrorProps) {
  return (
    <AriaFieldError
      className={cn(
        "text-sm text-red-700 dark:text-red-900 font-normal pt-2",
        className,
      )}
      {...props}
    />
  );
}

const fieldGroupVariants = cva("", {
  variants: {
    variant: {
      /* Base styles */
      default: [
        "relative flex h-9 w-full",
        "items-center overflow-hidden",
        "rounded-md",
        "border border-gray-200",
        "bg-white py-2",
        "text-xs",
        "ring-offset-white",
        /* Dark mode */
        "dark:border-gray-800",
        "dark:bg-slate-950",
        "dark:ring-offset-slate-950",
        /* Focus Within state */
        "data-[focus-within]:outline-none",
        "data-[focus-within]:ring-0",
        "data-[focus-within]:ring-offset-0",
        // "data-[focus-within]:ring-blue-600",
        "data-[focus-within]:border-blue-600",
        /* Keyboard focus state */
        "data-[focus-visible]:!ring-offset-2",
        "data-[focus-visible]:!ring-2",
        "data-[focus-visible]:!ring-blue-750",
        "data-[focus-visible]:!border-gray-200",
        /* Disabled state */
        "data-[disabled]:opacity-50",
      ],
      ghost: "",
    },
  },
  defaultVariants: {
    variant: "default",
  },
});

interface GroupProps
  extends AriaGroupProps,
    VariantProps<typeof fieldGroupVariants> {}

function FieldGroup({ className, variant, ...props }: GroupProps) {
  return (
    <AriaGroup
      className={composeRenderProps(className, (className) =>
        cn(fieldGroupVariants({ variant }), className),
      )}
      {...props}
    />
  );
}

export { Label, labelVariants, FieldGroup, fieldGroupVariants, FieldError };
