import { cn } from "@/lib/utils";
import { CaretDown, CaretUpDown } from "@phosphor-icons/react";
import { type VariantProps, cva } from "class-variance-authority";
import React, {
  type KeyboardEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import {
  Button as AriaButton,
  type ButtonProps as AriaButtonProps,
  composeRenderProps,
} from "react-aria-components";

const buttonVariants = cva(
  [
    // Base styles
    [
      "items-center justify-center",
      "whitespace-nowrap rounded-md",
      "ring-offset-background",
      "transition-colors transition-duration-100",
      "cursor-pointer",
    ].join(" "),

    // State styles
    [
      /* Disabled */
      "data-[disabled]:pointer-events-none",
      "data-[disabled]:opacity-50",
      /* Focus */
      "data-[focus]:outline-none",
      "data-[focus]:ring-0",
      "data-[focus]:ring-offset-0",
      /* Focus Visible */
      "data-[focus-visible]:outline-none",
      "data-[focus-visible]:ring-2",
      "data-[focus-visible]:ring-ring",
      "data-[focus-visible]:ring-offset-2",
      "data-[focus-visible]:ring-blue-750",
      /* Reset styles */
      "!focus-visible:outline-none",
      "focus:outline-none",
    ].join(" "),
  ],
  {
    variants: {
      variant: {
        input: [
          "rounded-md",
          "bg-slate-400/15",
          "text-slate-500",
          "inline-flex",
        ].join(" "),

        default: [
          "bg-violet-800",
          "text-white",
          "data-[hovered]:bg-violet-700",
          "data-[pressed]:bg-violet-900",
          "inline-flex",
        ].join(" "),

        destructive: [
          "border",
          "border-gray-200",
          "bg-background",
          "hover:bg-slate-400/5",
          "active:bg-slate-500/10",
          "shadow-sm",
          "text-slate-800",
          "inline-flex",
        ].join(" "),

        outline: [
          "border",
          "border-gray-200",
          "bg-background",
          "hover:bg-slate-400/5",
          "active:bg-slate-500/10",
          "shadow-sm",
          "text-slate-800",
          "inline-flex",
        ].join(" "),

        secondary: ["bg-slate-100", "text-slate-700", "inline-flex"].join(" "),

        ghost: [
          "hover:bg-gray-100",
          "active:bg-gray-200/50",
          "text-gray-800",
          "data-[hovered]:bg-gray-100",
          "data-[pressed]:bg-gray-200/50",
          "inline-flex",
        ].join(" "),

        none: "text-left",

        link: [
          "text-gray-900",
          "underline",
          // "underline-offset-2",
          "data-[hovered]:underline",
          "flex",
        ].join(" "),
      },

      size: {
        default: "h-10 px-4 py-2 text-sm font-medium",
        input: [
          "py-1 px-2",
          "absolute h-6",
          "top-1/2 -translate-y-1/2",
          "absolute right-2.5",
          "text-xs",
          "font-medium"
        ].join(" "),
        sm: "h-9 rounded-md min-w-[70px] px-3 text-xs font-normal",
        lg: "h-11 rounded-md px-8 text-sm font-medium",
        icon: "size-9 text-sm font-medium",
        xsIcon: "size-5 text-xs font-medium",
        none: "inline-block font-medium",
        label: "p-0 h-auto font-medium",
        link: "p-0 h-auto text-xxs",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  },
);

interface ButtonProps
  extends AriaButtonProps,
    VariantProps<typeof buttonVariants> {
  type?: "button" | "submit" | "reset";
}

export type ButtonSizeProps = VariantProps<typeof buttonVariants>["size"];

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, type = "button", ...props }, ref) => {
    return (
      <AriaButton
        ref={ref}
        type={type}
        className={composeRenderProps(className, (className) =>
          cn(
            buttonVariants({
              variant,
              size,
            }),
            className,
          ),
        )}
        {...props}
      />
    );
  },
);

interface DropdownButtonProps extends ButtonProps {
  ariaLabel: string;
  icon?: React.ReactNode;
  label: string;
  customLabel?: string;
  dropdownVariant?: "primary" | "secondary";
}

const DropdownButton = React.forwardRef<HTMLButtonElement, DropdownButtonProps>(
  (
    {
      ariaLabel,
      icon,
      label,
      customLabel,
      dropdownVariant = "primary",
      ...props
    },
    ref,
  ) => {
    const isSecondary = dropdownVariant === "secondary";
    return (
      <Button
        ref={ref}
        aria-label={ariaLabel}
        size="sm"
        variant="outline"
        className={cn(
          "px-2",
          isSecondary ? "text-gray-900 text-xs font-normal" : "text-gray-700",
        )}
        {...props}
      >
        {icon}
        <span
          className={cn(
            isSecondary ? "pl-1 text-gray-900" : "pl-1 text-gray-700",
          )}
        >
          {customLabel || label}
        </span>
        {isSecondary ? (
          <CaretDown className="ml-1 shrink-0 fill-gray-700" size={16} />
        ) : (
          <CaretUpDown className="ml-1 shrink-0 fill-gray-700" size={16} />
        )}
      </Button>
    );
  },
);

// Button Group
interface ButtonConfig {
  /**
   * label: The label of the button
   */
  label: string;
  /**
   * onClick: The function to be called when the button is clicked
   */
  onClick: () => void;
}

interface ButtonGroupProps {
  /**
   * buttonConfig: An array of button configurations consisting of the label and onClick function
   */
  buttonConfig: ButtonConfig[];
  /**
   * defaultSelectedIndex: The index of the button to be selected by default
   */
  defaultSelectedIndex?: number;
  /**
   * className: The class name to be applied to the button group
   */
  className?: string;
}

const ButtonGroup: React.FC<ButtonGroupProps> = ({
  buttonConfig,
  className,
  defaultSelectedIndex,
}) => {
  const [selectedIndex, setSelectedIndex] = useState<number>(
    defaultSelectedIndex ?? 0,
  );

  useEffect(() => {
    if (defaultSelectedIndex !== undefined) {
      setSelectedIndex(defaultSelectedIndex);
    }
  }, [defaultSelectedIndex]);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLFieldSetElement>) => {
      if (event.key === "ArrowRight") {
        setSelectedIndex((prevIndex) => (prevIndex + 1) % buttonConfig.length);
      } else if (event.key === "ArrowLeft") {
        setSelectedIndex((prevIndex) =>
          prevIndex === 0 ? buttonConfig.length - 1 : prevIndex - 1,
        );
      }
    },
    [buttonConfig.length],
  );

  return (
    <fieldset
      aria-label="Button group"
      onKeyDown={handleKeyDown}
      className={cn(
        "flex text-xxs tracking-wide font-normal cursor-pointer shadow-sm rounded-lg",
        className,
      )}
    >
      {buttonConfig.map((button, index) => (
        <AriaButton
          key={button.label}
          onPress={() => {
            button.onClick();
            setSelectedIndex(index);
          }}
          className={cn("border px-3 py-1 min-h-[30px]", {
            "rounded-l-md border-r-0": index === 0,
            "rounded-r-md border-l-0": index === buttonConfig.length - 1,
            "bg-violet-100 font-medium hover:bg-violet-200":
              index === selectedIndex,
            "bg-white font-normal hover:bg-gray-100": index !== selectedIndex,
          })}
          onFocus={() => setSelectedIndex(index)}
        >
          {button.label}
        </AriaButton>
      ))}
    </fieldset>
  );
};

export { Button, DropdownButton, buttonVariants, ButtonGroup };
export type { ButtonProps, ButtonGroupProps };
