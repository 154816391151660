import type { ReactNode } from "react";
import BarChart from "./BarChart";
import type { BarChartProps, PayloadItem } from "./BarChart.types";
import { ChartSkeleton, NoDataView } from "./ChartPlaceholders";

export interface BarChartWithStatesProps
  extends Omit<BarChartProps<PayloadItem>, "data"> {
  /**
   * Data to be displayed in the chart
   */
  data?: Record<string, string | number | number[] | Record<string, unknown>>[];

  /**
   * Whether the chart is in a loading state
   */
  isLoading?: boolean;

  /**
   * Custom loading component to show when isLoading is true
   */
  loadingComponent?: ReactNode;

  /**
   * Custom no data component to show when data is empty
   */
  noDataComponent?: ReactNode;

  /**
   * Whether to show the no data view when data is empty
   * @default true
   */
  showNoDataView?: boolean;

  /**
   * Additional class name for the loading state
   */
  loadingClassName?: string;

  /**
   * Additional class name for the no data state
   */
  noDataClassName?: string;
}

/**
 * A wrapper component for BarChart that handles loading and no-data states automatically.
 *
 * This component will show:
 * - A loading skeleton when `isLoading` is true
 * - A no-data view when data is empty and `isLoading` is false
 * - The actual chart when data is available and `isLoading` is false
 */
function BarChartWithStates({
  isLoading = false,
  data = [],
  loadingComponent,
  noDataComponent,
  showNoDataView = true,
  loadingClassName,
  noDataClassName,
  yAxisWidth = 56,
  ...props
}: BarChartWithStatesProps) {
  // Determine if we have data
  const hasData = Array.isArray(data) && data.length > 0;

  // Show loading state
  if (isLoading) {
    if (loadingComponent) {
      return <>{loadingComponent}</>;
    }
    return (
      <ChartSkeleton className={loadingClassName} yAxisWidth={yAxisWidth} />
    );
  }

  // Show no data state
  if (!hasData && showNoDataView) {
    if (noDataComponent) {
      return <>{noDataComponent}</>;
    }
    return <NoDataView className={noDataClassName} yAxisWidth={yAxisWidth} />;
  }

  // Show the chart
  return <BarChart data={data} yAxisWidth={yAxisWidth} {...props} />;
}

export default BarChartWithStates;
