import { getLocalTimeZone, today } from "@internationalized/date";
import {
  Button as AriaButton,
  Calendar as AriaCalendar,
  CalendarCell as AriaCalendarCell,
  type CalendarCellProps as AriaCalendarCellProps,
  CalendarGrid as AriaCalendarGrid,
  CalendarGridBody as AriaCalendarGridBody,
  type CalendarGridBodyProps as AriaCalendarGridBodyProps,
  CalendarGridHeader as AriaCalendarGridHeader,
  type CalendarGridHeaderProps as AriaCalendarGridHeaderProps,
  type CalendarGridProps as AriaCalendarGridProps,
  CalendarHeaderCell as AriaCalendarHeaderCell,
  type CalendarHeaderCellProps as AriaCalendarHeaderCellProps,
  type CalendarProps as AriaCalendarProps,
  type DateValue as AriaDateValue,
  Heading as AriaHeading,
  type RangeCalendarProps as AriaRangeCalendarProps,
  RangeCalendarStateContext as AriaRangeCalendarStateContext,
  composeRenderProps,
  useLocale,
} from "react-aria-components";

import { buttonVariants } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { CaretLeft, CaretRight } from "@phosphor-icons/react";
import { useContext } from "react";

const Calendar = AriaCalendar;

const CalendarHeading = (props: React.HTMLAttributes<HTMLElement>) => {
  const { direction } = useLocale();

  return (
    <header className="flex w-full items-center gap-1 px-1 pb-4" {...props}>
      <AriaButton
        slot="previous"
        className={cn(
          buttonVariants({ variant: "outline" }),
          "size-7 bg-transparent p-0 opacity-50",
          /* Hover */
          "data-[hovered]:opacity-100",
        )}
      >
        {direction === "rtl" ? (
          <CaretRight aria-hidden className="size-4" />
        ) : (
          <CaretLeft aria-hidden className="size-4" />
        )}
      </AriaButton>
      <AriaHeading className="grow text-center text-sm font-medium" />
      <AriaButton
        slot="next"
        className={cn(
          buttonVariants({ variant: "outline" }),
          "size-7 bg-transparent p-0 opacity-50",
          /* Hover */
          "data-[hovered]:opacity-100",
        )}
      >
        {direction === "rtl" ? (
          <CaretLeft aria-hidden className="size-4" />
        ) : (
          <CaretRight aria-hidden className="size-4" />
        )}
      </AriaButton>
    </header>
  );
};

const CalendarGrid = ({ className, ...props }: AriaCalendarGridProps) => (
  <AriaCalendarGrid
    className={cn(
      "border-separate border-spacing-x-0 border-spacing-y-1",
      className,
    )}
    {...props}
  />
);

const CalendarGridHeader = ({ ...props }: AriaCalendarGridHeaderProps) => (
  <AriaCalendarGridHeader {...props} />
);

const CalendarHeaderCell = ({
  className,
  ...props
}: AriaCalendarHeaderCellProps) => (
  <AriaCalendarHeaderCell
    className={cn(
      "w-9 rounded-md text-[0.8rem] font-normal text-slate-500 dark:text-slate-400",
      className,
    )}
    {...props}
  />
);

const CalendarGridBody = ({
  className,
  ...props
}: AriaCalendarGridBodyProps) => (
  <AriaCalendarGridBody className={cn("[&>tr>td]:p-0", className)} {...props} />
);

const todayDate = today(getLocalTimeZone());

const CalendarCell = ({ className, ...props }: AriaCalendarCellProps) => {
  const isRange = Boolean(useContext(AriaRangeCalendarStateContext));
  return (
    <AriaCalendarCell
      className={composeRenderProps(className, (className, renderProps) =>
        cn(
          buttonVariants({ variant: "ghost" }),
          "relative flex size-9 items-center justify-center p-0 text-sm font-normal",
          /* Disabled */
          renderProps.isDisabled &&
            "text-slate-500 opacity-50 dark:text-slate-400",
          /* Selected */
          renderProps.isSelected &&
            "bg-slate-900 text-slate-50 data-[focused]:bg-slate-900  data-[focused]:text-slate-50 dark:bg-slate-50 dark:text-slate-900 dark:data-[focused]:bg-slate-50 dark:data-[focused]:text-slate-900",
          /* Hover */
          renderProps.isHovered &&
            renderProps.isSelected &&
            (renderProps.isSelectionStart ||
              renderProps.isSelectionEnd ||
              !isRange) &&
            "data-[hovered]:bg-slate-900 data-[hovered]:text-slate-50 dark:data-[hovered]:bg-slate-50 dark:data-[hovered]:text-slate-900",
          /* Selection Start/End */
          renderProps.isSelected &&
            isRange &&
            !renderProps.isSelectionStart &&
            !renderProps.isSelectionEnd &&
            "rounded-none bg-slate-100 text-slate-900 dark:bg-slate-800 dark:text-slate-50",
          /* Outside Month */
          renderProps.isOutsideMonth &&
            "text-slate-500 opacity-50 data-[selected]:bg-slate-100/50 data-[selected]:text-slate-500 data-[selected]:opacity-30 dark:text-slate-400 dark:data-[selected]:bg-slate-800/50 dark:data-[selected]:text-slate-400",
          /* Current Date */
          renderProps.date.compare(todayDate) === 0 &&
            !renderProps.isSelected &&
            "bg-slate-100 text-slate-900 dark:bg-slate-800 dark:text-slate-50",
          /* Unavailable Date */
          renderProps.isUnavailable &&
            "cursor-default text-red-500  dark:text-red-900",
          renderProps.isInvalid &&
            "bg-red-500 text-slate-50 data-[focused]:bg-red-500 data-[hovered]:bg-red-500 data-[focused]:text-slate-50 data-[hovered]:text-slate-50 dark:bg-red-900 dark:text-slate-50 dark:data-[focused]:bg-red-900 dark:data-[hovered]:bg-red-900 dark:data-[focused]:text-slate-50 dark:data-[hovered]:text-slate-50",
          className,
        ),
      )}
      {...props}
    />
  );
};

interface JollyCalendarProps<T extends AriaDateValue>
  extends AriaCalendarProps<T> {
  errorMessage?: string;
}

interface JollyRangeCalendarProps<T extends AriaDateValue>
  extends AriaRangeCalendarProps<T> {
  errorMessage?: string;
}

export {
  Calendar,
  CalendarCell,
  CalendarGrid,
  CalendarGridBody,
  CalendarGridHeader,
  CalendarHeaderCell,
  CalendarHeading,
};
export type { JollyCalendarProps, JollyRangeCalendarProps };
