import { CaretDown } from "@phosphor-icons/react";
import {
  Button as AriaButton,
  type ButtonProps as AriaButtonProps,
  ListBox as AriaListBox,
  type ListBoxProps as AriaListBoxProps,
  type PopoverProps as AriaPopoverProps,
  Select as AriaSelect,
  type SelectProps as AriaSelectProps,
  SelectValue as AriaSelectValue,
  type SelectValueProps as AriaSelectValueProps,
  type ValidationResult as AriaValidationResult,
  Text,
  composeRenderProps,
} from "react-aria-components";

import { cn } from "@/lib/utils";

import { FieldError, Label } from "./field";
import { ListBoxItem } from "./list-box";
import { Popover } from "./popover";

const Select = AriaSelect;

const SelectItem = ListBoxItem;

const SelectValue = <T extends object>({
  className,
  ...props
}: AriaSelectValueProps<T>) => (
  <AriaSelectValue
    className={composeRenderProps(className, (className) =>
      cn(
        "line-clamp-1 data-[placeholder]:text-slate-500 text-xs",
        /* Description */
        "[&>[slot=description]]:hidden",
        className,
      ),
    )}
    {...props}
  />
);

interface SelectTriggerProps extends AriaButtonProps {
  size?: "sm" | "md";
}

const SelectTrigger = ({
  className,
  children,
  size = "md",
  ...props
}: SelectTriggerProps) => (
  <AriaButton
    className={composeRenderProps(className, (className) =>
      cn(
        "group flex w-full items-center justify-between rounded-md border border-slate-200 bg-white ring-offset-white data-[pressed=true]:border-blue-600",
        {
          "h-10 px-3 py-2 text-sm": size === "md",
          "h-9 px-3 py-2 text-xs": size === "sm",
        },
        /* Disabled */
        "data-[disabled]:opacity-50",
        /* Focused */
        "data-[focused=true]:outline-none data-[focused=true]:ring-offset-0 data-[focused=true]:ring-0",
        /* Keyboard focus specifically */
        "data-[focus-visible=true]:!ring-offset-2 data-[focus-visible=true]:!ring-2 data-[focus-visible=true]:!border-slate-200 data-[focus-visible=true]:ring-blue-750",
        /* Resets */
        "focus-visible:outline-none",
        className,
      ),
    )}
    {...props}
  >
    {composeRenderProps(children, (children) => (
      <>
        {children}
        <CaretDown
          aria-hidden="true"
          className={cn(
            "text-gray-600 group-active:text-gray-900 group-focus:text-gray-900",
            {
              "size-4": size === "md",
              "size-3": size === "sm",
            },
          )}
        />
      </>
    ))}
  </AriaButton>
);

const SelectPopover = ({ className, ...props }: AriaPopoverProps) => (
  <Popover
    className={composeRenderProps(className, (className) =>
      cn("w-[--trigger-width] bg-white", className),
    )}
    {...props}
  />
);

const SelectListBox = <T extends object>({
  className,
  ...props
}: AriaListBoxProps<T>) => (
  <AriaListBox
    className={composeRenderProps(className, (className) =>
      cn(
        "max-h-[inherit] overflow-auto px-1 pt-1.5 pb-2.5 outline-none [clip-path:inset(0_0_0_0_round_calc(var(--radius)-2px))]",
        className,
      ),
    )}
    {...props}
  />
);

interface JollySelectProps<T extends object>
  extends Omit<AriaSelectProps<T>, "children"> {
  label?: string;
  size?: "sm" | "md";
  description?: string;
  errorMessage?: string | ((validation: AriaValidationResult) => string);
  items?: Iterable<T>;
  children: React.ReactNode | ((item: T) => React.ReactNode);
}

function JollySelect<T extends object>({
  label,
  description,
  errorMessage,
  children,
  className,
  items,
  size = "md",
  ...props
}: JollySelectProps<T>) {
  return (
    <Select
      className={composeRenderProps(className, (className) =>
        cn("group flex flex-col", className),
      )}
      {...props}
    >
      <Label
        className={cn({
          "text-xs": size === "sm",
          "text-sm": size === "md",
        })}
      >
        {label}
        {props.isRequired && <span>*</span>}
      </Label>
      <SelectTrigger size={size}>
        <SelectValue />
      </SelectTrigger>
      {description && (
        <Text
          className="text-sm text-slate-500 dark:text-slate-400"
          slot="description"
        >
          {description}
        </Text>
      )}
      <FieldError>{errorMessage}</FieldError>
      <SelectPopover
        className={cn("w-[--trigger-width] bg-white", {
          "mt-1": size === "sm",
        })}
      >
        <SelectListBox items={items}>{children}</SelectListBox>
      </SelectPopover>
    </Select>
  );
}

export {
  Select,
  SelectValue,
  SelectTrigger,
  SelectItem,
  SelectPopover,
  SelectListBox,
  JollySelect,
};
export type { JollySelectProps };
