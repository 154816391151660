import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/controls/command";
import type { NewsFeedPublisherType } from "@/data-access/news";
import { cn } from "@/lib/utils";
import { useEffect, useRef, useState } from "react";
import { Separator } from "../controls/separator";

interface PublisherSearchDropdownProps {
  publishers: NewsFeedPublisherType[];
  isLoading: boolean;
  onSearch: (term: string) => void;
  onSelect: (id: string) => void;
  selectedPublishers: Record<string, NewsFeedPublisherType[]>;
  multiSelect?: boolean;
  className?: string;
  autoOpen?: boolean;
  cancelEdit?: () => void;
  maintainValueOnSelect?: boolean;
}

const PublisherSearchDropdown = ({
  publishers,
  isLoading,
  onSearch,
  onSelect,
  selectedPublishers,
  multiSelect = true,
  className,
  autoOpen = false,
  cancelEdit,
  maintainValueOnSelect = false,
}: PublisherSearchDropdownProps) => {
  const [commandListVisible, setCommandListVisible] = useState(false);
  const commandRef = useRef<HTMLDivElement>(null);
  const [searchValue, setSearchValue] = useState("");
  const [isInteractingWithList, setIsInteractingWithList] = useState(false);
  const [wasCleared, setWasCleared] = useState(false);
  const [displayValue, setDisplayValue] = useState("");
  const selectionInProgressRef = useRef(false);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // Don't close if we're in the middle of a selection
      if (selectionInProgressRef.current) {
        return;
      }

      if (
        commandRef.current &&
        !commandRef.current.contains(event?.target as Node)
      ) {
        setCommandListVisible(false);
        setIsInteractingWithList(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  // Update display value when selected publishers change, but only when maintainValueOnSelect is true
  useEffect(() => {
    if (!maintainValueOnSelect) {
      return; // Don't update display value if not maintaining value
    }

    const selected = Object.values(selectedPublishers).flat();
    if (selected.length === 0) {
      setDisplayValue("");
      return;
    }

    if (!multiSelect && selected.length === 1) {
      // In single select mode, show the selected publisher name
      setDisplayValue(selected[0]?.name || "");
    }
  }, [selectedPublishers, multiSelect, maintainValueOnSelect]);

  const getFirstPublisherLogo = (publisher: NewsFeedPublisherType) => {
    const logos = publisher.logos.filter((logo) => logo.image?.url);
    return logos?.[0]?.image?.url ?? "";
  };

  const renderPublisherIcon = (publisher: NewsFeedPublisherType) => {
    const logo = getFirstPublisherLogo(publisher);
    const fallbackIconClass =
      "h-5 w-5 flex items-center justify-center text-xxs text-gray-600 bg-gray-100 rounded";

    const getFallbackInitials = () => {
      const words = publisher.name.split(" ");
      return words.length > 1
        ? words
            .slice(0, 2)
            .map((word) => word[0])
            .join("")
            .toUpperCase()
        : publisher.name.substring(0, 2).toUpperCase();
    };

    const getFallbackIcon = () => {
      const icon = document.createElement("div");
      icon.className = fallbackIconClass;
      icon.textContent = getFallbackInitials();
      return icon;
    };

    const iconContent = logo ? (
      <img
        src={logo}
        alt={publisher.name}
        className="h-5 w-5 rounded object-contain"
        onLoad={(e) => {
          const img = e.target as HTMLImageElement;
          if (img.naturalWidth / img.naturalHeight !== 1) {
            img.style.display = "none";
            img.parentNode?.appendChild(getFallbackIcon());
          }
        }}
      />
    ) : (
      <div className={fallbackIconClass}>{getFallbackInitials()}</div>
    );

    return <div className="flex justify-center max-w-8">{iconContent}</div>;
  };

  const selectedPub = (publisher: NewsFeedPublisherType) =>
    Object.values(selectedPublishers).some((tiers) =>
      tiers.some((p) => p.id === publisher.id),
    );

  const renderPublisherItem = (
    publisher: NewsFeedPublisherType,
    key: string,
    searchable = true,
  ) => (
    <CommandItem
      key={key}
      value={key}
      onSelect={(value) => {
        // Set flag to prevent dropdown from closing during selection
        selectionInProgressRef.current = true;

        const strippedValue = value.startsWith("s: ") ? value.slice(3) : value;
        onSelect(strippedValue);

        // Find the selected publisher to display its name
        const selectedPub = publishers.find((p) => p.id === strippedValue);

        // Small delay to ensure the selection is processed
        setTimeout(() => {
          selectionInProgressRef.current = false;
        }, 50);

        if (!multiSelect) {
          // In single select mode, close the dropdown after selection
          setCommandListVisible(false);

          if (maintainValueOnSelect && selectedPub) {
            // If maintaining value, show the selected publisher name
            setDisplayValue(selectedPub.name);
            setSearchValue(selectedPub.name);
          } else {
            // Clear search value if not maintaining value
            setSearchValue("");
          }
        } else {
          // In multi-select mode, keep the dropdown open but reset the flag
          setTimeout(() => {
            // Keep the list interaction state active
            setIsInteractingWithList(true);
          }, 50);
        }
      }}
      showIcon={selectedPub(publisher)}
      className={cn("gap-[7px] text-gray-900 text-xs")}
      keywords={searchable ? [publisher.name, publisher.url] : undefined}
      data-highlighted={selectedPub(publisher)}
    >
      {renderPublisherIcon(publisher)}
      <span className="whitespace-nowrap overflow-hidden text-ellipsis">
        {publisher.name}
      </span>
      <span className="text-xxs text-gray-300 whitespace-nowrap overflow-hidden text-ellipsis">
        {publisher.url}
      </span>
    </CommandItem>
  );

  const renderSelectedPublishers = () => {
    const selected = Object.values(selectedPublishers).flat();
    if (!selected.length) return null;

    return (
      <>
        {selected.map((publisher) =>
          publisher
            ? renderPublisherItem(publisher, `s: ${publisher.id}`, false)
            : null,
        )}
        {!searchValue && <Separator className="my-1" />}
      </>
    );
  };

  const renderUnselectedPublishers = () => {
    const selectedIds = Object.values(selectedPublishers)
      .flat()
      .map((p) => p.id);

    // In single select mode, filter out the selected publisher
    const filteredPublishers = !multiSelect
      ? publishers.filter((pub) => !selectedIds.includes(pub.id))
      : publishers;

    return filteredPublishers.map((publisher) =>
      renderPublisherItem(publisher, publisher.id),
    );
  };

  return (
    <Command
      className={cn("relative overflow-visible", className)}
      ref={commandRef}
      shouldFilter={true}
      filter={(_, search, keywords) => {
        const extendedValue = `${keywords ?? [].join(" ")}`;
        const trimmedSearch = search.trim().toLowerCase();
        return extendedValue.toLowerCase().includes(trimmedSearch) ? 1 : 0;
      }}
      // Prevent the Command component from capturing space key
      onKeyDown={(e) => {
        // Let the input handle space key
        e.stopPropagation();
      }}
    >
      <CommandInput
        onFocus={() => setCommandListVisible(true)}
        placeholder="Search publications..."
        value={
          maintainValueOnSelect ? searchValue || displayValue : searchValue
        }
        onValueChange={(value) => {
          setSearchValue(value);

          // Track if the input was cleared
          if (value === "" && (searchValue || displayValue)) {
            setWasCleared(true);
          }

          // Clear display value when user starts typing, but only if maintaining value
          if (value && maintainValueOnSelect) {
            setDisplayValue("");
          }

          // Ensure dropdown is visible when typing
          if (value.trim() !== "") setCommandListVisible(true);

          onSearch(value);
        }}
        loading={isLoading}
        autoFocus={autoOpen}
        onBlur={() => {
          // Only trigger cancelEdit if we're not interacting with list and not in selection process
          if (!isInteractingWithList && !selectionInProgressRef.current) {
            // If the input was cleared and we're clicking away, call cancelEdit
            if (wasCleared && searchValue === "" && cancelEdit) {
              cancelEdit();
              setWasCleared(false);
            } else if (cancelEdit) cancelEdit();
          }
        }}
      />
      <CommandList
        className={cn(
          commandListVisible ? "absolute" : "hidden",
          "bg-white",
          "border rounded-md shadow-md",
          "scrollbar-thin",
          "top-10",
          "z-50",
        )}
        onMouseEnter={() => {
          setIsInteractingWithList(true);
        }}
        onMouseLeave={() => {
          if (!selectionInProgressRef.current) setIsInteractingWithList(false);
        }}
      >
        <CommandEmpty className="text-gray-600 w-[342px] py-2 text-xs px-4">
          {isLoading ? "Searching publications..." : "No results found."}
        </CommandEmpty>
        <CommandGroup>
          {renderSelectedPublishers()}
          {renderUnselectedPublishers()}
        </CommandGroup>
      </CommandList>
    </Command>
  );
};

export default PublisherSearchDropdown;
