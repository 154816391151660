import IconButton from "@/components/ui/icon-button";
import { cn } from "@/lib/utils";
import {
  ArrowsOut,
  BookmarkSimple,
  MagnifyingGlass,
} from "@phosphor-icons/react";
import Placeholder from "@tiptap/extension-placeholder";
import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { useEffect, useRef } from "react";
import BooleanHighlight from "./BooleanHighlightExtension";

export interface InlineSearchEditorProps {
  handleSearch: (value: string) => void;
  handleExpand: () => void;
  content?: string;
  handleSave: () => void;
}

/**
 * A fixed version of the InlineSearchEditor that doesn't trap focus
 */
const InlineSearchEditor = ({
  content,
  handleExpand,
  handleSave,
  handleSearch,
}: InlineSearchEditorProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const lastInteractionWasKeyboard = useRef(false);
  const typingTimeout = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const handleTab = (e: KeyboardEvent) => {
      if (e.key === "Tab") {
        lastInteractionWasKeyboard.current = true;
      }
    };

    const handleMouseDown = () => {
      lastInteractionWasKeyboard.current = false;
    };

    document.addEventListener("keydown", handleTab);
    document.addEventListener("mousedown", handleMouseDown);

    return () => {
      document.removeEventListener("keydown", handleTab);
      document.removeEventListener("mousedown", handleMouseDown);
    };
  }, []);

  const editor = useEditor({
    extensions: [
      Placeholder.configure({
        placeholder: "Search...",
        emptyEditorClass: cn(
          "cursor-text tracking-wide before:content-[attr(data-placeholder)]",
          "before:absolute before:opacity-50",
          "before-pointer-events-none min-w-1",
        ),
      }),
      StarterKit.configure({
        heading: false,
        paragraph: {
          HTMLAttributes: {
            class: "inline-block m-0 p-0",
          },
        },
      }),
      BooleanHighlight,
    ],
    content,
    editorProps: {
      attributes: {
        class: cn(
          "text-xs font-sans tracking-wide text-gray-800",
          "whitespace-nowrap overflow-auto [&::-webkit-scrollbar]:hidden scrollbar-none",
          "max-w-[calc(100%-3rem)] no-scrollbar [&>p]:block",
          "!outline-none",
          "focus:!outline-none",
          lastInteractionWasKeyboard.current &&
            "focus-visible:!outline focus-visible:!outline-2 focus-visible:!outline-blue-750 focus-visible:!outline-offset-2 focus-visible:!rounded-sm",
        ),
      },
      handleKeyDown: (_view, event) => {
        if (event.key === "Enter") {
          event.preventDefault(); // Prevents new line creation
          onSearch(); // Triggers search on Enter
          return true; // Handled
        }
        return false; // Let other keys behave normally
      },
    },
    onUpdate: ({ editor }) => {
      if (editor.isEmpty) {
        handleSearch("");
      } else {
        const plainText = editor.getText().trim();
        handleSearch(plainText);
      }

      if (typingTimeout.current) clearTimeout(typingTimeout.current);
      typingTimeout.current = setTimeout(() => {
        handleSearch(editor.getText().trim());
      }, 500);
    },
  });

  if (!editor) {
    return <p>Loading editor...</p>;
  }

  const onSearch = () => {
    if (editor) {
      const plainText = editor.getText().trim();
      handleSearch(plainText);
    }
  };

  return (
    <div className="flex w-80 drop-shadow">
      <div className="relative flex-1" ref={containerRef}>
        {/* Use a regular div without tabIndex to avoid focus trapping */}
        <div
          className={cn(
            "flex h-[30px] items-center border border-gray-200 border-r-0 rounded-l-md font-normal p-2 bg-white overflow-hidden",
            editor.isFocused && "border-blue-600",
          )}
        >
          <div className="flex-1 min-w-0">
            <EditorContent editor={editor} />
          </div>

          <div className="absolute right-0 flex items-center ml-2">
            {!editor.isEmpty && (
              <IconButton
                icon={<BookmarkSimple />}
                aria-label={"save key messages"}
                size={"sm"}
                variant={"icon-only"}
                className={"p-0"}
                tooltipLabel={"Save as key message"}
                crossOffset={-60}
                onPressChange={handleSave}
              />
            )}

            <IconButton
              icon={<ArrowsOut />}
              aria-label="expand"
              size="sm"
              variant="icon-only"
              className="p-1 mr-1"
              tooltipLabel="Expand"
              crossOffset={30}
              onPressChange={handleExpand}
            />
          </div>
        </div>
      </div>
      <IconButton
        icon={<MagnifyingGlass />}
        aria-label="search key message"
        size="sm"
        className={cn(
          "rounded-none rounded-r-md h-[30px] w-8",
          !editor.isFocused && " border border-gray-200 bg-white",
        )}
        onPressChange={onSearch}
        variant={!editor.isFocused ? "icon-only" : "default"}
      />
    </div>
  );
};

export default InlineSearchEditor;
