import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { ArrowsIn, CircleNotch, MagnifyingGlass } from "@phosphor-icons/react";
import Placeholder from "@tiptap/extension-placeholder";
import { type Editor, EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import BooleanHighlight from "./BooleanHighlightExtension";
import type { InlineSearchEditorProps } from "./InlineSearchEditor";

interface TiptapEditorProps {
  extensions?: NonNullable<Parameters<typeof useEditor>[0]>["extensions"];
  content?: string;
  editorProps?: NonNullable<Parameters<typeof useEditor>[0]>["editorProps"];
  className?: string;
  placeholder?: string;
  onUpdate?: (editor: Editor) => void;
  autoFocus?: NonNullable<Parameters<typeof useEditor>[0]>["autofocus"];
}

export interface ExpandedSearchEditorProps extends InlineSearchEditorProps {
  isSearching?: boolean;
  onUpdate?: (editor: Editor) => void;
}

/**
 * Base editor component with TipTap integration
 * @component
 * @param {Object} props - The component props
 * @param {Array} [props.extensions=[]] - Array of TipTap extensions to enhance editor functionality
 * @param {string} [props.content="Type here for boolean search"] - Initial content for the editor
 * @param {Object} [props.editorProps={}] - Additional editor configuration options
 * @param {string} [props.className] - Additional CSS classes to customize the editor's appearance
 * @param {string} [props.placeholder="Type Here for boolean search"] - Placeholder text shown when the editor is empty
 * @returns {JSX.Element} The rendered editor component
 */
const BaseEditor = forwardRef<Editor | null, TiptapEditorProps>(
  (
    {
      extensions = [],
      content = "Type here for boolean search",
      editorProps = {},
      className,
      placeholder = "Type Here for boolean search",
      onUpdate,
      autoFocus = false,
    },
    ref,
  ) => {
    const editor = useEditor({
      extensions: [
        Placeholder.configure({
          placeholder,
          emptyEditorClass: cn(
            "cursor-text tracking-[0.12px] before:content-[attr(data-placeholder)]",
            "before:absolute before:opacity-50",
            "before-pointer-events-none",
          ),
        }),
        StarterKit,
        BooleanHighlight,
        ...extensions,
      ],
      autofocus: autoFocus,
      content,
      editorProps: {
        attributes: {
          class: cn(
            "text-xs font-sans tracking-[0.12px] rounded-md border border-gray-200 drop-shadow-sm p-3",
            "focus:outline-none focus:ring-blue-500 focus:border-blue-500",
            className,
          ),
        },
        handleKeyDown: (_view, event) => {
          if (event.key === "Enter") {
            event.preventDefault(); // Prevents new line creation
            return true; // Handled
          }
          if (event.key === "Tab") {
            // Don't prevent default tab behavior - let it navigate out of the editor
            return false; // Not handled, let browser handle tab
          }
          return false;
        },

        ...editorProps,
      },
      onUpdate: ({ editor }) => {
        if (onUpdate) {
          onUpdate(editor);
        }
      },
    });

    useImperativeHandle(ref, () => editor as Editor, [editor]);

    if (!editor) {
      return <p>Loading editor...</p>;
    }

    return <EditorContent editor={editor} />;
  },
);

// #region ExpandedSearchEditor

const ExpandedSearchEditor = ({
  handleSave,
  content,
  handleExpand: handleCollapse,
  handleSearch,
  isSearching = false,
  onUpdate,
}: ExpandedSearchEditorProps) => {
  const editorRef = useRef<Editor | null>(null);
  const [isEditorEmpty, setIsEditorEmpty] = useState(true);

  const onSearch = () => {
    if (editorRef.current) {
      const plainText = editorRef.current.getText();
      handleSearch(plainText);
    }
  };

  //TODO: After searching for a term, variants should be interchanged between "Save as key message" and "Seacrh" revisit this  base editor

  return (
    <div className={"bg-white mb-3"}>
      <div className={"flex items-center py-2"}>
        <MagnifyingGlass size={14} className={"text-gray-600"} />
        <span className={"font-medium text-sm ml-1 text-gray-700"}>Search</span>
      </div>
      <BaseEditor
        ref={editorRef}
        content={content}
        className="min-h-24"
        placeholder="Search..."
        onUpdate={(editor) => {
          onUpdate?.(editor);
          setIsEditorEmpty(editor.isEmpty);
        }}
        autoFocus={true}
      />
      <div className={"tracking-wide flex items-center my-2 justify-end"}>
        <Button
          className={"text-xs"}
          variant={"ghost"}
          size={"sm"}
          onPressChange={handleCollapse}
        >
          <ArrowsIn className={"text-gray-600 mr-1"} size={14} />
          Close
        </Button>
        <Button
          variant={"outline"}
          className={"text-xs mx-2"}
          onPressChange={handleSave}
          size={"sm"}
          isDisabled={isEditorEmpty && content?.length === 0}
        >
          Save as key message
        </Button>
        <Button
          className={"text-xs flex items-center"}
          onPressChange={onSearch}
          size={"sm"}
        >
          {isSearching && (
            <CircleNotch size={16} className={"mr-1 animate-spin"} />
          )}
          <span> {isSearching ? "Searching" : "Search"}</span>
        </Button>
      </div>
    </div>
  );
};

export { ExpandedSearchEditor };
export default BaseEditor;
