import { cn } from "@/lib/utils";
import { XCircle } from "@phosphor-icons/react";
import {
  type ForwardedRef,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Input as AriaInput,
  type InputProps as AriaInputProps,
  TextArea as AriaTextArea,
  type TextAreaProps as AriaTextAreaProps,
  TextField as AriaTextField,
  type TextFieldProps as AriaTextFieldProps,
  type ValidationResult as AriaValidationResult,
  type InputRenderProps,
  composeRenderProps,
} from "react-aria-components";
import { FieldError, Label } from "./field";
import IconButton from "./icon-button.tsx";

const TextField = AriaTextField;

const Input = forwardRef<HTMLInputElement, AriaInputProps>(
  ({ className, ...props }, forwardedRef) => {
    return (
      <AriaInput
        className={composeRenderProps(className, (className) =>
          cn(
            // Base styles
            [
              "flex h-9 w-full rounded-md border",
              "border-slate-200 bg-white px-3 py-2",
              "ring-offset-white",
              "text-xs",
              "file:border-0 file:bg-transparent",
              "file:text-xs file:font-medium",
              "placeholder:text-slate-500",
              // Dark mode base
              "dark:border-slate-800 dark:bg-slate-950",
              "dark:ring-offset-slate-950",
              "dark:placeholder:text-slate-400",
            ].join(" "),
            // State styles
            [
              // Disabled
              "data-[disabled]:opacity-50",
              // Focus (mouse or keyboard)
              "data-[focused=true]:outline-none",
              "data-[focused=true]:ring-offset-0",
              "data-[focused=true]:ring-0",
              "data-[focused=true]:ring-blue-600",
              "data-[focused=true]:border-blue-600",
              "dark:data-[focused=true]:ring-slate-500",
              // Keyboard focus
              "data-[focus-visible=true]:!ring-offset-2",
              "data-[focus-visible=true]:!ring-2",
              "data-[focus-visible=true]:!border-gray-200",
              "data-[focus-visible=true]:ring-blue-750",
              "dark:data-[focus-visible=true]:ring-slate-300",
            ].join(" "),
            className,
          ),
        )}
        {...props}
        ref={forwardedRef}
      />
    );
  },
);

const TextArea = forwardRef<HTMLTextAreaElement, AriaTextAreaProps>(
  ({ className, ...props }, forwardedRef) => {
    const textareaRef = useRef<HTMLTextAreaElement>(null);

    const adjustHeight = () => {
      const textarea = textareaRef.current;
      if (textarea) {
        textarea.style.height = "auto";
        textarea.style.height = `${textarea.scrollHeight}px`;
      }
    };

    useEffect(() => {
      adjustHeight();
    }, [props.value]);

    return (
      <AriaTextArea
        className={composeRenderProps(className, (className) =>
          cn(
            // Base styles
            [
              "flex w-full rounded-md border",
              "border-slate-200 bg-white px-3 py-2",
              "ring-offset-white",
              "placeholder:text-slate-500",
              "dark:border-slate-800 dark:bg-slate-950",
              "dark:ring-offset-slate-950",
              "dark:placeholder:text-slate-400",
            ].join(" "),
            // State styles
            [
              // Disabled
              "data-[disabled]:cursor-not-allowed",
              "data-[disabled]:opacity-50",
              // Focus handling
              "focus-visible:outline-none",
              // Any focus (mouse or keyboard)
              "data-[focused=true]:outline-none",
              "data-[focused=true]:ring-0",
              "data-[focused=true]:ring-offset-0",
              "data-[focused=true]:ring-blue-600",
              "data-[focused=true]:border-blue-600",
              "dark:data-[focused=true]:ring-slate-500",
              // Keyboard focus
              "data-[focus-visible=true]:ring-2",
              "data-[focus-visible=true]:ring-offset-2",
              "data-[focus-visible=true]:!border-slate-200",
              "dark:data-[focused=true]:ring-slate-300",
              // Utilities
              "resize-none min-h-[36px] overflow-hidden",
            ].join(" "),
            className,
          ),
        )}
        {...props}
        onChange={(e) => {
          props.onChange?.(e);
          adjustHeight();
        }}
        ref={(ref) => {
          // Handle both refs
          if (typeof forwardedRef === "function") {
            forwardedRef(ref);
          } else if (forwardedRef) {
            forwardedRef.current = ref;
          }
          textareaRef.current = ref;
        }}
      />
    );
  },
);

interface JollyTextFieldProps
  extends Omit<
    AriaTextFieldProps,
    "className" | "style" | "description" | "placeholder"
  > {
  label?: string;
  description?: string;
  errorMessage?: string | ((validation: AriaValidationResult) => string);
  textArea?: boolean;
  className?:
    | string
    | ((
        values: InputRenderProps & {
          defaultClassName: string | undefined;
        },
      ) => string);
  style?:
    | React.CSSProperties
    | ((
        values: InputRenderProps & {
          defaultStyle: React.CSSProperties;
        },
      ) => React.CSSProperties);
  rows?: number;
  placeholder?: string;
  // Add clearable functionality props
  clearable?: boolean;
  onClear?: () => void;
  value?: string;
  required?: boolean;
  size?: "sm" | "md" | "lg";
  labelVariant?: "default" | "secondary";
}

const JollyTextField = forwardRef<
  HTMLInputElement | HTMLTextAreaElement,
  JollyTextFieldProps
>(
  (
    {
      label,
      description,
      errorMessage,
      textArea,
      className,
      style,
      rows,
      placeholder,
      // Clearable props
      clearable = true,
      value,
      required = false,
      size = "md",
      labelVariant,
      ...props
    },
    forwardedRef: ForwardedRef<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    const [isFocused, setIsFocused] = useState<boolean>(false);

    // Determine if the clear button should be shown
    const shouldShowClearButton =
      clearable && Boolean(value) && (isFocused || !!errorMessage);

    const handleFocus = () => {
      setIsFocused(true);
    };

    const handleBlur = () => {
      setIsFocused(false);
    };

    return (
      <AriaTextField
        className={cn("group flex", "flex-col", {
          "text-xs": size === "sm",
          "text-sm": size === "md",
          "text-base": size === "lg",
        })}
        {...props}
        isInvalid={!!errorMessage}
      >
        {label && (
          <Label
            className={cn({
              "text-xs": size === "sm",
              "text-sm": size === "md",
              "text-base": size === "lg",
            })}
            variant={labelVariant}
          >
            {label}
            {required && <span>*</span>}
          </Label>
        )}
        {textArea ? (
          <TextArea
            className={cn(
              {
                "text-xs": size === "sm",
                "text-sm": size === "md",
                "text-base": size === "lg",
              },
              className,
            )}
            style={style}
            value={value}
            onChange={
              props.onChange
                ? (e) => props.onChange?.(e.target.value)
                : undefined
            }
            onKeyDown={props.onKeyDown}
            onFocus={handleFocus}
            onBlur={handleBlur}
            placeholder={description || placeholder}
            rows={rows}
            ref={
              (forwardedRef as React.Ref<HTMLTextAreaElement>) || textAreaRef
            }
          />
        ) : (
          <div className={cn("relative")}>
            <Input
              className={
                //changed for visual consistency on author edit if you need to change the right spacing for clear change it with a variant (ideally come up with a sane standard)
                cn(
                  !!errorMessage && "border-red-500",
                  clearable && "pr-9",
                  className,
                )
              }
              style={style}
              placeholder={description || placeholder}
              value={value}
              onChange={
                props.onChange
                  ? (e) => props.onChange?.(e.target.value)
                  : undefined
              }
              onKeyDown={props.onKeyDown}
              ref={(forwardedRef as React.Ref<HTMLInputElement>) || inputRef}
              onFocus={handleFocus}
              onBlur={handleBlur}
            />
            {shouldShowClearButton && (
              //changed for visual consistency on author edit if you need to change the right spacing for clear change it with a variant (ideally come up with a sane standard)
              <div
                className="absolute right-0 top-1/2 -translate-y-1/2"
                aria-hidden="true"
              >
                <IconButton
                  icon={
                    <XCircle
                      weight={"fill"}
                      className={"text-gray-400 hover:text-gray-600 w-4 h-4"}
                    />
                  }
                  aria-label="Clear text"
                  variant="icon-only"
                  excludeFromTabOrder
                  onPressChange={() => props.onChange?.("")}
                />
              </div>
            )}
          </div>
        )}
        <FieldError
          className={cn(
            {
              "text-xs": size === "sm",
              "text-sm": size === "md",
              "text-base": size === "lg",
            },
            "tracking-wide",
          )}
        >
          {errorMessage}
        </FieldError>
      </AriaTextField>
    );
  },
);

export { Input, TextField, JollyTextField, TextArea };
export type { JollyTextFieldProps };
