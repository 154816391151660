import { cn } from "@/lib/utils";
import {
  type AvailableChartColorsKeys,
  getColorClassName,
} from "@/lib/utils/chartUtils";
import { memo, useEffect, useState } from "react";
import type { ChartTooltipProps, PayloadItem } from "./BarChart.types";

const ChartTooltip = ({
  active,
  payload,
  label,
  valueFormatter,
  mode = "solid",
  formatXAxisTick,
}: ChartTooltipProps<PayloadItem>) => {
  const [isVisible, setIsVisible] = useState(false);
  const [tooltipData, setTooltipData] = useState(payload);

  useEffect(() => {
    if (active && payload?.length) {
      setTooltipData(payload);
      setIsVisible(true);
    } else {
      const timer = setTimeout(() => setIsVisible(false), 225); // Match transition duration
      return () => clearTimeout(timer);
    }
  }, [active, payload]);

  if (tooltipData?.length) {
    const isActive = active && isVisible;
    return (
      <div
        className={cn(
          // base
          "rounded-md border text-[10px] shadow-md",
          // border color
          "border-gray-200 dark:border-gray-800",
          // background color
          "bg-white dark:bg-gray-950 transform-gpu will-change-[opacity,transform]",
          // text
          "transition-[opacity,transform] duration-225 ease-out pointer-events-auto",
          isActive ? "opacity-100 translate-y-0" : "opacity-0 translate-y-1",
        )}
      >
        <div className={cn("px-4 pt-2")}>
          <p
            className={cn(
              // text color
              "text-gray-600 dark:text-gray-50",
            )}
          >
            {formatXAxisTick
              ? formatXAxisTick(label, tooltipData[0]?.id as number)
              : label}
          </p>
        </div>
        <div className={cn("space-y-1 px-4 pt-1 pb-2")}>
          {tooltipData.map(({ value, category, color }, index) => (
            <div
              key={`id-${index}-${value}-${category}`}
              className={cn(
                "flex items-center justify-between space-x-8 transform-gpu",
                "transition-[opacity,transform] duration-225 ease-out",
                "will-change-[opacity,transform]",
                active
                  ? "opacity-100 translate-x-0"
                  : "opacity-0 translate-x-1",
              )}
              style={{
                transitionDelay: `${index * 50}ms`,
              }}
            >
              <div className="flex items-center space-x-2">
                <span
                  aria-hidden="true"
                  className={cn(
                    "size-2 shrink-0 rounded-sm",
                    getColorClassName(
                      color as AvailableChartColorsKeys,
                      "bg",
                      mode,
                    ),
                  )}
                />
                <p
                  className={cn(
                    // base
                    "whitespace-nowrap text-right",
                    // text color
                    "text-gray-900 dark:text-gray-300 capitalize",
                  )}
                >
                  {category}
                </p>
              </div>
              <p
                className={cn(
                  // base
                  "whitespace-nowrap text-right font-medium tabular-nums",
                  // text color
                  "text-gray-600 dark:text-gray-50",
                )}
              >
                {valueFormatter(value)}
              </p>
            </div>
          ))}
        </div>
      </div>
    );
  }
  return null;
};

ChartTooltip.displayName = "ChartTooltip";
export default memo(ChartTooltip, (prevProps, nextProps) => {
  return (
    prevProps.label === nextProps.label &&
    prevProps.active === nextProps.active &&
    JSON.stringify(prevProps.payload) === JSON.stringify(nextProps.payload) &&
    prevProps.mode === nextProps.mode &&
    prevProps.valueFormatter === nextProps.valueFormatter &&
    prevProps.formatXAxisTick === nextProps.formatXAxisTick
  );
});
