import { useOnWindowResize } from "@/lib/hooks/useOnWindowResize";
import { cn } from "@/lib/utils";
import {
  type AvailableChartColorsKeys,
  getColorClassName,
} from "@/lib/utils/chartUtils.ts";
import { forwardRef, useLayoutEffect, useRef } from "react";
import type { ChartLegendProps, LegendProps } from "./BarChart.types";

// LegendItem Component
interface LegendItemProps {
  name: string;
  color: AvailableChartColorsKeys;
  onClick?: (name: string, color: AvailableChartColorsKeys) => void;
  activeLegend?: string;
  mode?: "solid" | "gradient";
}

// LegendItem Component
const LegendItem = <T extends string>({
  name,
  color,
  onClick,
  activeLegend,
  mode = "solid",
}: LegendItemProps & { name: T }) => {
  const isClickable = !!onClick;
  const isInactive = activeLegend && activeLegend !== name;

  return (
    <li
      className={cn(
        "inline-flex items-center min-w-fit pr-3 transition-opacity duration-200 ease-in-out",
        isClickable &&
          "cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-800",
        isInactive && "opacity-40",
      )}
      onClick={(e) => {
        e.stopPropagation();
        onClick?.(name, color);
      }}
      onKeyDown={(e) => {
        if (e.key === "Enter" || e.key === " ") {
          e.preventDefault();
          onClick?.(name, color);
        }
      }}
      tabIndex={isClickable ? 0 : undefined}
      role={isClickable ? "button" : undefined}
    >
      <div
        className={cn(
          "w-2 h-2 rounded-full shrink-0",
          getColorClassName(color, "bg", mode),
        )}
      />
      <span className="ml-1 text-[9px] text-gray-700 dark:text-gray-300 whitespace-nowrap">
        {name}
      </span>
    </li>
  );
};

// Legend Component
const Legend = forwardRef<HTMLOListElement, LegendProps>((props, ref) => {
  const {
    categories,
    colors = [],
    className,
    onClickLegendItem,
    activeLegend,
    mode = "solid",
    ...other
  } = props;

  return (
    <ol
      ref={ref}
      className={cn(
        "flex flex-wrap items-center list-none p-0 m-0 transition-all duration-300 ease-in-out",
        className,
      )}
      {...other}
    >
      {categories.map((category, index) => (
        <LegendItem
          // biome-ignore lint/suspicious/noArrayIndexKey: <category names may not be unique, using combination with category>
          key={`legend-${category}-${index}`}
          name={category}
          color={colors[index] as AvailableChartColorsKeys}
          onClick={onClickLegendItem}
          activeLegend={activeLegend}
          mode={mode}
        />
      ))}
    </ol>
  );
});
// ChartLegend Component
const ChartLegend = ({
  payload,
  categoryColors,
  setLegendHeight,
  activeLegend,
  onClick,
  rotateXAxisTick,
  yAxisWidth,
}: ChartLegendProps) => {
  const legendRef = useRef<HTMLDivElement>(null);
  const resizeTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  // More accurate height measurement function
  const updateLegendHeight = () => {
    const height = legendRef.current?.clientHeight;
    if (height) {
      // Add padding and account for potential wrapping
      const calculatedHeight = Math.ceil(height) + 10;
      setLegendHeight(calculatedHeight);
    }
  };

  // Update height on mount and when payload changes
  useLayoutEffect(() => {
    updateLegendHeight();
    // Small delay to ensure rendering is complete
    const timeout = setTimeout(updateLegendHeight, 50);
    return () => clearTimeout(timeout);
  }, [payload, rotateXAxisTick]);

  // Update on window resize with debounce
  useOnWindowResize(() => {
    if (resizeTimeoutRef.current) {
      clearTimeout(resizeTimeoutRef.current);
    }

    resizeTimeoutRef.current = setTimeout(() => {
      updateLegendHeight();
    }, 100);
  });

  const filteredPayload = payload.filter((item) => item.type !== "none");

  // Calculate left padding to align with chart content
  const leftPadding = yAxisWidth ? `${yAxisWidth}px` : "0";

  return (
    <div
      ref={legendRef}
      className={cn(
        "w-full transition-all duration-300 ease-in-out",
        "flex flex-wrap items-center",
        rotateXAxisTick ? "pt-10" : "pt-2",
      )}
      style={{ paddingLeft: leftPadding }}
    >
      <Legend
        categories={filteredPayload.toReversed().map((entry) => entry.value)}
        colors={filteredPayload
          .toReversed()
          .map(
            (entry) =>
              categoryColors.get(entry.value) as AvailableChartColorsKeys,
          )}
        onClickLegendItem={onClick}
        activeLegend={activeLegend}
        rotateXAxisTick={rotateXAxisTick}
      />
    </div>
  );
};

// Set display names
LegendItem.displayName = "LegendItem";
Legend.displayName = "Legend";
ChartLegend.displayName = "ChartLegend";

export { ChartLegend };
